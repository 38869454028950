import React, { Component } from "react";
import { connect } from "react-redux";
import {
  saveComment,
  deleteComment,
  populateComment,
  commentOnChange,
  clearComments,
} from "../../actions/inspections";
import AddIcon from "@material-ui/icons/Add";
import { DefaultButtons } from "./Sections";

class Comments extends Component {
  // constructor(props) {
  //   super(props);
  // }

  saveBeforeUnload = () => {
    this.save();
  };

  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      console.log("Before closing");
      return this.saveBeforeUnload();
    });
    window.addEventListener("popstate", (ev) => {
      ev.preventDefault();
      console.log("Before navigating");
      return this.saveBeforeUnload();
    });
  };

  componentDidMount() {
    // Activate the event listener
    this.populateComments();
    this.setupBeforeUnloadListener();
  }

  addComment = async () => {
    const blankComment = { comment: "", image: "" };
    this.props.saveComment(blankComment);
  };

  populateComments = () => {
    this.props.state.walkaround.comments.map((commentID) =>
      this.props.populateComment(commentID)
    );
  };

  onBlur = async (event) => {
    const commentID = event.target.name.split("-")[1];
    const comment = { _id: commentID, comment: event.target.value };
    this.props.saveComment(comment);
  };

  onImgChange = async (event) => {
    event.persist();
    const image = await this.toBase64(event.target.files[0]);
    const commentID = event.target.name.split("-")[1];
    const comment = { _id: commentID, image };
    console.log(comment);
    this.props.saveComment(comment);
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // toBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const img = document.createElement("img");

  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = (e) => {
  //       img.src = e.target.result;

  //       img.onload = function () {
  //         var canvas = document.createElement("canvas");
  //         let ctx = canvas.getContext("2d");
  //         ctx.drawImage(img, 0, 0);

  //         var MAX_WIDTH = 250;
  //         var MAX_HEIGHT = 250;
  //         var width = img.width;
  //         var height = img.height;

  //         if (width > height) {
  //           if (width > MAX_WIDTH) {
  //             height *= MAX_WIDTH / width;
  //             width = MAX_WIDTH;
  //           }
  //         } else {
  //           if (height > MAX_HEIGHT) {
  //             width *= MAX_HEIGHT / height;
  //             height = MAX_HEIGHT;
  //           }
  //         }
  //         canvas.width = width;
  //         canvas.height = height;
  //         ctx = canvas.getContext("2d");
  //         ctx.drawImage(img, 0, 0, width, height);

  //         const dataurl = canvas.toDataURL("image/jpeg");
  //         resolve(dataurl);
  //       };
  //     };
  //     reader.onerror = (error) => reject(error);
  //   });

  next = () => {
    this.save();
    this.props.next();
    this.props.clearComments();
  };

  save = () => {
    const commentIDs = this.props.comments.map((comment) => comment._id);
    console.log(commentIDs);
    this.props.state.walkaround.comments = commentIDs;
    this.props.save();
  };

  saveExit = () => {
    this.save();
    this.props.saveExit();
  };

  back = () => {
    this.save();
    this.props.back();
    this.props.clearComments();
  };

  render() {
    const { inSpanish, comments, deleteComment } = this.props;
    return (
      <>
        <div className="db" style={{ height: "75px" }}>
          {this.props.state.walkaround.comments.length >
            this.props.comments.length && (
            <button
              className="b br3 ma3 mr5 pv2 ph3 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
              onClick={this.populateComments}
            >
              {inSpanish
                ? `Mostrar comentarios anteriores`
                : "Show previous comments"}
            </button>
          )}
          <button
            className="b br3 ma3 mr5 pv2 ph3 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
            onClick={this.addComment}
            style={{ float: "right" }}
          >
            <AddIcon className="pr1" />
            {inSpanish ? "Aggregar Commentario" : "Add Comment"}
          </button>
        </div>
        <div
          className="flex flex-grow flex-wrap justify-center"
          style={{ height: "400px", overflow: "auto" }}
        >
          {comments.map((comment, index) => (
            <div
              className="br3 ba b--black-20 pa4 pt0 ma2 shadow-5"
              key={index}
            >
              <div className="flex justify-between">
                <p className="f5">{"Comment " + index}</p>
                <p
                  className="f3 link dim red pointer"
                  onClick={async () => {
                    const deleteConfimation = window.confirm(
                      inSpanish
                        ? "Seguro que quiere borrar este comentario?\nNo abra manera de recuperarlo."
                        : "Are you sure?\nThere will be no way to recover this comment."
                    );
                    deleteConfimation &&
                      (async () => {
                        await deleteComment(comment._id);
                        this.save();
                      })();
                  }}
                >
                  X
                </p>
              </div>

              <div className="flex justify-between items-center ">
                <textarea
                  name={`comment-${comment._id}`}
                  className="f3"
                  style={{ width: "40vw", height: "250px" }}
                  onBlur={this.onBlur}
                  onChange={(e) =>
                    this.props.commentOnChange({
                      ...comment,
                      comment: e.target.value,
                    })
                  }
                  value={comment.comment}
                ></textarea>
                <div
                  className="flex flex-column justify-end pl2"
                  style={{ width: "300px", height: "300px" }}
                >
                  <label
                    className="ba b--dashed"
                    style={{ width: "250px", height: "250px" }}
                    htmlFor={`image-${comment._id}`}
                  >
                    <img
                      src={comment.image}
                      alt=""
                      style={{
                        width: "250px",
                        height: "250px",
                        objectFit: "contain",
                      }}
                    />
                  </label>
                  <input
                    type="file"
                    name={`image-${comment._id}`}
                    className="tc br-pill ba b--black-20 pt2 pb2 pl2 shadow-5 fileUpload"
                    onChange={this.onImgChange}
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <DefaultButtons
          props={{
            inSpanish,
            next: this.next,
            back: this.back,
            save: this.save,
            saveExit: this.saveExit,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  inspections: { comments },
  options: { inSpanish },
}) => ({ inSpanish, comments });

export default connect(mapStateToProps, {
  saveComment,
  deleteComment,
  populateComment,
  commentOnChange,
  clearComments,
})(Comments);
