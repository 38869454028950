import React from "react";
import Tilt from "react-tilt";
import logo from "./logo512.png";
import "./style.css";

export default function Page404() {
  return (
    <>
      <Tilt
        className="Tilt center"
        options={{ max: 25, reverse: true, scale: 1 }}
        // style={{ height: "70%" }}
      >
        <div className="Tilt-inner pa3">
          <p className="f1 tc white pb0 mv0">404 Page not found</p>
          <div className="f3 tc mt1 ">Where are you going?</div>
          <img
            className="center"
            style={{ paddingTop: "5px", height: "50vh" }}
            src={logo}
            alt="logo"
          />
        </div>
      </Tilt>
    </>
  );
}
