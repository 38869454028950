import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getInspections,
  getInspection,
  displayInspection,
  deleteInspection,
} from "../../actions/inspections";
import { withRouter } from "react-router-dom";
import LinkButton from "../../Components/LinkButton/LinkButton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import InspectionCard from "../../Components/InspectionCard/InspectionCard";
import FilterModal from "../../Components/FilterModal/FilterModal";
import ExportCSV from "../../Components/ExportCSV/ExportCSV";

const mapStateToProps = ({
  inspections: { inspections },
  options: { inSpanish },
  session: { admin },
}) => ({
  admin,
  inspections,
  inSpanish,
});

class InspectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false,
      currentQuery: "",
    };
  }

  componentDidMount() {
    this.props.getInspections();
  }

  handleClick = (id) => {
    console.log(this);
    if (id) {
      this.props.getInspection(id);
      this.props.displayInspection(id, true, this.state.currentQuery);
    }
  };

  routeClick = (route) => {
    this.props.history.push(route);
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      showFilterModal: !prevState.showFilterModal,
    }));
  };

  setFilterActive = (query) => {
    this.setState({ currentQuery: query });
  };

  clearFilter = () => {
    this.props.getInspections();
    this.setState({ currentQuery: "" });
  };

  render() {
    const {
      inspections,
      inSpanish,
      deleteInspection,
      admin,
      getInspections,
    } = this.props;
    return (
      <>
        <div className="w-90" style={{ margin: "auto" }}>
          <p className="tc subheader pv3 mv0">
            {inSpanish ? "Inspecciones" : "Inspections"}
          </p>
          <div className="floatRight">
            {this.state.currentQuery !== "" && (
              <button
                className="f4 br2 mh2 ph3 pv1 ba b--white bg-black-10 white"
                onClick={this.clearFilter}
              >
                {inSpanish ? "Quitar Filtro" : "Clear Filter"}
              </button>
            )}
            <button
              className="f4 br2 mh2 ph3 pv1 ba b--white bg-black-10 white"
              onClick={this.toggleModal}
            >
              {inSpanish ? "Filtrar" : "Filter"}
            </button>
          </div>
          <div
            className="br3 white ma40 flex flex-column"
            style={{ height: "500px", overflow: "hidden", margin: "auto" }}
          >
            <div
              style={{
                overflowY: "auto",
                display: "flex",
                flexWrap: "wrap",
                flexGrow: 1,
              }}
            >
              {inspections.map((inspection, i) => {
                return (
                  <InspectionCard
                    key={i}
                    admin={admin}
                    id={inspection._id}
                    deleteInspection={deleteInspection}
                    truckNumber={inspection.truckID.truckNumber}
                    completed={inspection.completed}
                    lastEdit={inspection.updatedAt}
                    lastEditor={inspection.lastEditor.name}
                    onClick={() => this.handleClick(inspection._id)}
                  />
                );
              })}
            </div>
            <div style={{ display: "flex", justifyItems: "space-between" }}>
              <LinkButton
                className="b br3 ma3 pr3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
                route=""
              >
                <ArrowLeftIcon />
                {inSpanish ? "Regresar" : "Back"}
              </LinkButton>
              <ExportCSV
                inspections={inspections}
                inSpanish={inSpanish}
                fileName="WalkaroundCSV"
              />
            </div>
          </div>
        </div>
        {this.state.showFilterModal && (
          <FilterModal
            inSpanish={inSpanish}
            close={this.toggleModal}
            getInspections={getInspections}
            activateFilter={this.setFilterActive}
          />
        )}
      </>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, {
    getInspections,
    displayInspection,
    getInspection,
    deleteInspection,
  })(InspectionList)
);
