import * as apiUtil from "../util/inspections";
import {
  RECEIVE_INSPECTIONS,
  DISPLAY_INSPECTION,
  RECEIVE_COMMENT,
  REMOVE_COMMENT,
  CLEAR_COMMENTS,
  RECEIVE_INSPECTION_FORM,
  REMOVE_INSPECTION,
} from "./types";
import { receiveErrors, receiveSuccess } from "./messages";
import { pendingResponse } from "./pending";

const receiveInspections = (inspections) => ({
  type: RECEIVE_INSPECTIONS,
  payload: inspections,
});

const removeInspection = (id) => ({
  type: REMOVE_INSPECTION,
  payload: id,
});

const receiveInspectionForm = (inspections) => ({
  type: RECEIVE_INSPECTION_FORM,
  payload: inspections,
});

export const displayInspection = (id, display, query = "") => ({
  type: DISPLAY_INSPECTION,
  payload: { id, display, query },
});

const receiveComment = (comment) => ({
  type: RECEIVE_COMMENT,
  payload: comment,
});

const removeComment = (id) => ({
  type: REMOVE_COMMENT,
  payload: id,
});

export const commentOnChange = (comment) => ({
  type: RECEIVE_COMMENT,
  payload: comment,
});

export const clearComments = () => ({
  type: CLEAR_COMMENTS,
});

export const getInspections = (query = "") => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.getInspections(query);
    console.log(response);
    dispatch(receiveInspections(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const inspectionsByTruck = (truck_id) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.inspectionsByTruck(truck_id);
    dispatch(receiveInspections(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const getInspection = (id) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.getInspection(id);
    console.log(response.data);
    dispatch(receiveInspections(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const getInspectionForm = (id) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.getInspectionForm(id);
    console.log(response.data);
    dispatch(receiveInspectionForm(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const uploadInspection = (inspection) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.saveInspection(inspection);
    console.log(response.data);
    dispatch(receiveInspectionForm(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const deleteInspection = (id) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.deleteInspection(id);
    console.log(response.data);
    dispatch(removeInspection(id));
    dispatch(receiveSuccess(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const saveComment = (comment) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.saveComment(comment);
    console.log(response.data);
    dispatch(receiveComment(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const deleteComment = (id) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.deleteComment(id);
    console.log(response.data);
    dispatch(removeComment(id));
    dispatch(receiveSuccess(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const populateComment = (id) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.populateComment(id);
    console.log(response.data);
    dispatch(receiveComment(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};
