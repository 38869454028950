import * as apiUtil from "./inspections";

export const buildCSVData = async (ids) => {
  let inspections = ids.map(async (id) => getInspection(id));
  inspections = await Promise.all(inspections);
  console.log(inspections);
  let csvHeadings = getHeadings();
  const csvData = inspections.map((inspection) => {
    const currRow = [];
    Object.keys(inspection).forEach((key) => {
      key === "walkaround1"
        ? flattenWA(inspection[key], currRow)
        : key === "walkaround2"
        ? //TODO: Add case for User obj and Truck Obj
          flattenWA(inspection[key], currRow)
        : key === "truckID"
        ? currRow.push(inspection[key]["truckNumber"])
        : key === "lastEditor"
        ? currRow.push(inspection[key]["name"])
        : key !== "_id" && key !== "__v" && currRow.push(inspection[key]);
    });
    return currRow;
  });
  return [csvHeadings, ...csvData];
};

const flattenWA = (wa, currRow) => {
  let commentSpace = 10;
  Object.keys(wa).forEach((field) => {
    const curr = wa[field];
    field !== "_id" &&
      field !== "__v" &&
      !(curr instanceof Array) &&
      currRow.push(curr);
  });
  wa.comments.forEach((comment, i) => {
    console.log(i);
    commentSpace--;
    currRow.push(comment.comment);
    currRow.push(`https://walkaround.jorgebarrueta.me/comment/${comment._id}`);
  });

  [...Array(commentSpace)].forEach((_, i) => {
    console.log(i);
    currRow.push(` `);
    currRow.push(` `);
  });
};

const getHeadings = () => {
  const headings = [
    "CompletedWA1",
    "CompletedWA2",
    "Created On",
    "Last Editor",
    "Truck",
    "Last Updated",
  ];
  const wa1 = [
    "WA1 Date",
    "WA1 Driver 1",
    "WA1 License 1",
    "WA1 Driver 2",
    "WA1 License 2",
    "WA1 Odometer",
    "WA1 Tire 10 PSI",
    "WA1 Tire 10 Brand",
    "WA1 Tire 10 32nds",
    "WA1 Tire 1 PSI",
    "WA1 Tire 1 Brand",
    "WA1 Tire 1 32nds",
    "WA1 Tire 2 PSI",
    "WA1 Tire 2 Brand",
    "WA1 Tire 2 32nds",
    "WA1 Tire 3 PSI",
    "WA1 Tire 3 Brand",
    "WA1 Tire 3 32nds",
    "WA1 Tire 4 PSI",
    "WA1 Tire 4 Brand",
    "WA1 Tire 4 32nds",
    "WA1 Tire 5 PSI",
    "WA1 Tire 5 Brand",
    "WA1 Tire 5 32nds",
    "WA1 Tire 6 PSI",
    "WA1 Tire 6 Brand",
    "WA1 Tire 6 32nds",
    "WA1 Tire 7 PSI",
    "WA1 Tire 7 Brand",
    "WA1 Tire 7 32nds",
    "WA1 Tire 8 PSI",
    "WA1 Tire 8 Brand",
    "WA1 Tire 8 32nds",
    "WA1 Tire 9 PSI",
    "WA1 Tire 9 Brand",
    "WA1 Tire 9 32nds",
    "WA1 Signature",
  ];
  [...Array(10)].forEach(
    (_, i) => wa1.push(`WA1 Comment ${i + 1}`) && wa1.push(`WA1 Image ${i + 1}`)
  );
  const wa2 = [
    "WA2 Date",
    "WA2 Driver 1",
    "WA2 License 1",
    "WA2 Driver 2",
    "WA2 License 2",
    "WA2 Odometer",
    "WA2 Tire 10 PSI",
    "WA2 Tire 10 Brand",
    "WA2 Tire 10 32nds",
    "WA2 Tire 1 PSI",
    "WA2 Tire 1 Brand",
    "WA2 Tire 1 32nds",
    "WA2 Tire 2 PSI",
    "WA2 Tire 2 Brand",
    "WA2 Tire 2 32nds",
    "WA2 Tire 3 PSI",
    "WA2 Tire 3 Brand",
    "WA2 Tire 3 32nds",
    "WA2 Tire 4 PSI",
    "WA2 Tire 4 Brand",
    "WA2 Tire 4 32nds",
    "WA2 Tire 5 PSI",
    "WA2 Tire 5 Brand",
    "WA2 Tire 5 32nds",
    "WA2 Tire 6 PSI",
    "WA2 Tire 6 Brand",
    "WA2 Tire 6 32nds",
    "WA2 Tire 7 PSI",
    "WA2 Tire 7 Brand",
    "WA2 Tire 7 32nds",
    "WA2 Tire 8 PSI",
    "WA2 Tire 8 Brand",
    "WA2 Tire 8 32nds",
    "WA2 Tire 9 PSI",
    "WA2 Tire 9 Brand",
    "WA2 Tire 9 32nds",
    "WA2 Signature",
  ];
  [...Array(10)].forEach(
    (_, i) => wa2.push(`WA2 Comment ${i + 1}`) && wa2.push(`WA2 Image ${i + 1}`)
  );

  return [...headings, ...wa1, ...wa2];
};

export const getInspection = async (id) => {
  const response = await apiUtil.getInspection(id);
  return response.data[0];
};
