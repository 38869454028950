import React, { Component, Fragment } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";

class Alerts extends Component {
  componentDidUpdate(prevProps) {
    const { success, error, alert } = this.props;
    if (success !== prevProps.success && success !== "") {
      alert.success(success);
    }
    if (error !== prevProps.error && error !== "") {
      alert.error(error);
    }
  }

  render() {
    return <Fragment />;
  }
}

export default connect(({ messages: { success, error } }) => ({
  success,
  error,
}))(withAlert()(Alerts));
