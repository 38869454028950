import { DialogContent } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { populateComment } from "../../actions/inspections";

class Comment extends Component {
  componentDidMount() {
    this.props.populateComment(this.props.match.params.id);
  }

  close = () => {
    window.close();
  };
  render() {
    return (
      <div className="formPage ">
        <button
          className="left mt4 ml5 pa3 b red br3 bg-black-30"
          onClick={this.close}
        >
          X
        </button>
        <div className="center mt6" style={{ display: "flex" }}>
          <p className="f2 w-40 bg-white-50 br3 shadow-3">
            {this.props.comment.comment}
          </p>
          <img className="w-40" src={this.props.comment.image} alt="" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ inspections: { comments } }) => ({
  comment: comments[0],
});

export default connect(mapStateToProps, {
  populateComment,
})(Comment);
