import React, { Component } from "react";
import { connect } from "react-redux";
import { getTruck, uploadTruck } from "../../actions/trucks";
import { withRouter } from "react-router-dom";
import Switch from "@material-ui/core/Switch";

const mapStateToProps = ({ trucks, options: { inSpanish } }) => ({
  trucks,
  inSpanish,
});

class RegisterTruck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      truckNumber: "",
      vin: "",
      model: "",
      active: true,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.getTruck(this.props.match.params.id);
      const { trucks } = this.props;
      trucks.length === 0 && this.props.history.push("/register-truck/");
      this.setState({
        _id: trucks[0]._id,
        truckNumber: trucks[0].truckNumber,
        vin: trucks[0].vin,
        model: trucks[0].model,
        active: trucks[0].active,
      });
    }
  };

  goBack = () => this.props.history.goBack();

  changeHandler = async (e) => {
    await this.setState({
      [e.target.name]:
        e.target.name === "active" ? e.target.checked : e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.uploadTruck(this.state);
    this.props.history.goBack();
  };

  render() {
    const { truckNumber, vin, model, active } = this.state;
    const { inSpanish } = this.props;
    return (
      <div>
        <article className="br3 ba bw3 white b--white-20 mv4 w-100 w-50-m w-25-l mw6 shadow-3 center">
          <form className="pa4 white-80" onSubmit={this.onSubmit}>
            <div className="measure">
              <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                <legend className="subheader fw6 ph0 mh0">
                  {inSpanish ? "Registro de Troca" : "Register Truck"}
                </legend>
                <div className="mv3">
                  <Switch
                    checked={active}
                    onChange={this.changeHandler}
                    name="active"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <label className="fw6 lh-copy f6" htmlFor="truckNumber">
                    {active
                      ? inSpanish
                        ? "Activo"
                        : "Active"
                      : inSpanish
                      ? "Desactivado"
                      : "Disactivated"}
                  </label>
                </div>
                <div className="mv3">
                  <label className="db fw6 lh-copy f6" htmlFor="truckNumber">
                    {inSpanish ? "Numero de Troca" : "Truck Number"}
                  </label>
                  <input
                    className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                    type="text"
                    name="truckNumber"
                    id="truckNumber"
                    value={truckNumber}
                    onChange={this.changeHandler}
                    readOnly={Boolean(this.props.match.params.id)}
                    required
                  />
                </div>
                <div className="mv3">
                  <label className="db fw6 lh-copy f6" htmlFor="vin">
                    VIN
                  </label>
                  <input
                    className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                    type="text"
                    name="vin"
                    id="vin"
                    value={vin}
                    onChange={this.changeHandler}
                    required
                  />
                </div>
                <div className="mv3">
                  <label className="db fw6 lh-copy f6" htmlFor="model">
                    {inSpanish ? "Modelo" : "Model"}
                  </label>
                  <input
                    className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                    type="text"
                    name="model"
                    id="model"
                    value={model}
                    onChange={this.changeHandler}
                    required
                  />
                </div>
              </fieldset>
              <div className="flex justify-between">
                <input
                  className="b ph3 pv2 input-reset ba white b--white bg-transparent grow pointer f5 dib"
                  type="submit"
                  value="Register Truck"
                />
                <p
                  className="f6 tl link dim white dib pointer underline-hover"
                  onClick={this.goBack}
                >
                  {inSpanish ? "Atras" : "Back"}
                </p>
              </div>
            </div>
          </form>
        </article>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, { getTruck, uploadTruck })(RegisterTruck)
);
