import React, { Component } from "react";
import { connect } from "react-redux";
import { getUser, uploadUser } from "../../actions/users";
import { withRouter } from "react-router-dom";
import Switch from "@material-ui/core/Switch";

const mapStateToProps = ({ users, options: { inSpanish } }) => ({
  users,
  inSpanish,
});

class RegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      username: "",
      password: "",
      active: true,
      admin: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.getUser(this.props.match.params.id);
      const { users } = this.props;
      users.length === 0 && this.props.history.push("/register-user/");
      this.setState({
        _id: users[0]._id,
        name: users[0].name,
        username: users[0].username,
        password: "XXXXXXXX",
        active: users[0].active,
        admin: users[0].admin,
      });
    }
  };

  changeHandler = async (e) => {
    await this.setState({
      [e.target.name]:
        e.target.name === "active" || e.target.name === "admin"
          ? e.target.checked
          : e.target.value,
    });
  };

  goBack = () => this.props.history.goBack();

  onSubmit = (e) => {
    e.preventDefault();
    this.props.uploadUser(this.state);
    this.props.history.goBack();
  };

  render() {
    const { name, username, password, active, admin } = this.state;
    const { inSpanish } = this.props;
    return (
      <div>
        <article className="br3 ba bw3 white b--white-20 mv4 w-100 w-50-m w-25-l mw6 shadow-3 center">
          <form className="pa4 white-80" onSubmit={this.onSubmit}>
            <div className="measure">
              <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                <legend className="subheader fw6 ph0 mh0">
                  {inSpanish ? "Registro de Usuario" : "Register User"}
                </legend>
                <div className="mv3">
                  <Switch
                    checked={active}
                    onChange={this.changeHandler}
                    name="active"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <label className="fw6 lh-copy f6" htmlFor="active">
                    {active
                      ? inSpanish
                        ? "Activo"
                        : "Active"
                      : inSpanish
                      ? "Desactivado"
                      : "Disactivated"}
                  </label>
                </div>
                <div className="mv3">
                  <label className="db fw6 lh-copy f6" htmlFor="name">
                    {inSpanish ? "Nombre Completo" : "Full Name"}
                  </label>
                  <input
                    className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.changeHandler}
                    required
                  />
                </div>
                <div className="mv3">
                  <label className="db fw6 lh-copy f6" htmlFor="username">
                    {inSpanish ? "Usario" : "Username"}
                  </label>
                  <input
                    className={`pa2 input-reset ba black b--white bg-white-50 w-100 ${
                      Boolean(this.props.match.params.id)
                        ? "o-50"
                        : "hover-bg-white"
                    }`}
                    type="text"
                    name="username"
                    value={username}
                    onChange={this.changeHandler}
                    disabled={Boolean(this.props.match.params.id)}
                    required
                  />
                </div>
                <div className="mv3">
                  <label className="db fw6 lh-copy f6" htmlFor="password">
                    {inSpanish ? "Contraseña" : "Password"}
                  </label>
                  <input
                    className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.changeHandler}
                    required
                  />
                </div>
                <div className="mv3">
                  <Switch
                    checked={admin}
                    onChange={this.changeHandler}
                    name="admin"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <label className="fw6 lh-copy f6" htmlFor="admin">
                    {admin
                      ? "Admin"
                      : inSpanish
                      ? "Usuario General"
                      : "General User"}
                  </label>
                </div>
              </fieldset>
              <div className="flex justify-between">
                <input
                  className="b ph3 pv2 input-reset ba white b--white bg-transparent grow pointer f5 dib"
                  type="submit"
                  value={inSpanish ? "Actualizar Usuario" : "Submit User"}
                />
                <p
                  className="f6 tl link dim white dib pointer underline-hover"
                  onClick={this.goBack}
                >
                  {inSpanish ? "Atras" : "Back"}
                </p>
              </div>
            </div>
          </form>
        </article>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, { getUser, uploadUser })(RegisterUser)
);
