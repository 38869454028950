import React from "react";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import SaveIcon from "@material-ui/icons/Save";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Input from "../../Components/Input/Input";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";

export const Section0 = ({
  state,
  inSpanish,
  onChange,
  next,
  save,
  saveExit,
}) => {
  return (
    <>
      <div
        className="flex flex-grow flex-wrap justify-center"
        style={{ height: "60%", overflow: "auto" }}
      >
        <div className="mh5-l mh4-m mh2">
          <h2 className="f2 db mb4 mt3 white tc">
            {inSpanish ? "Chofer 1" : "Driver 1"}
          </h2>
          <Input
            inSpanish={inSpanish}
            name="Driver1Name"
            spLabel="Nombre"
            enLabel="Name"
            inputProps={{
              onChange: onChange,
              value: state.walkaround.Driver1Name,
            }}
          />
          <Input
            inSpanish={inSpanish}
            name="Driver1License"
            spLabel="Licencia"
            enLabel="License"
            inputProps={{
              onChange: onChange,
              value: state.walkaround.Driver1License,
            }}
          />
        </div>
        <div className="mh5-l mh4-m mh2">
          <h2 className="f2 db mb4 mt3 white tc">
            {inSpanish ? "Chofer 2" : "Driver 2"}
          </h2>
          <Input
            inSpanish={inSpanish}
            name="Driver2Name"
            spLabel="Nombre"
            enLabel="Name"
            inputProps={{
              onChange: onChange,
              value: state.walkaround.Driver2Name,
            }}
          />
          <Input
            inSpanish={inSpanish}
            name="Driver2License"
            spLabel="Licencia"
            enLabel="License"
            inputProps={{
              onChange: onChange,
              value: state.walkaround.Driver2License,
            }}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="right b br3 ma3 mr2 pv2 ph3 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
          onClick={saveExit}
        >
          <ExitToAppIcon />
          {inSpanish ? "Guardar y Salir" : "Save & Exit"}
        </button>
        <button
          className="right b br3 ma3 mr2 pv2 ph3 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
          onClick={save}
        >
          <SaveIcon className="pr1" />
          {inSpanish ? "Guardar" : "Save"}
        </button>
        <button
          className="right b br3 ma3 ml1 pl3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
          onClick={next}
        >
          {inSpanish ? "Siguiente" : "Next"}
          <ArrowRightIcon />
        </button>
      </div>
    </>
  );
};

export const Section1 = ({
  state,
  inSpanish,
  onChange,
  next,
  save,
  back,
  saveExit,
}) => {
  const [selectedDate, setSelectedDate] = React.useState(
    !isNaN(Date.parse(state.walkaround.Date))
      ? new Date(state.walkaround.Date)
      : new Date()
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };
  return (
    <>
      <div
        className="flex flex-grow flex-wrap justify-center"
        style={{ height: "60%", overflow: "auto" }}
      >
        <div className="mh5-l mh4-m mh2 tc">
          <h2 className="f2 db mb4 mt3 white tc">
            {inSpanish ? "Info General" : "General Info"}
          </h2>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <label className="f4 db mb2 mt5 white tc">
              {inSpanish ? "Fecha de Inspeccion" : "Date of Inspection"}
            </label>
            <div className="br3 ba b--black-20 bg-white pa3-l pa3-m p2 shadow-5">
              <KeyboardDatePicker
                margin="normal"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
          <Input
            inSpanish={inSpanish}
            name="Odometer"
            spLabel="Odometro"
            enLabel="Odometer"
            inputProps={{
              onChange: onChange,
              value: state.walkaround.Odometer,
            }}
          />
        </div>
      </div>
      <DefaultButtons props={{ inSpanish, next, back, save, saveExit }} />
    </>
  );
};

export const Section2 = ({
  state,
  inSpanish,
  onChange,
  next,
  save,
  back,
  saveExit,
}) => {
  return (
    <>
      <div
        className="flex flex-grow flex-wrap justify-center"
        style={{ height: "60%", overflow: "auto" }}
      >
        <h2 className="f2 db mb4 mt3 white tc">
          {inSpanish ? "Llantas" : "Tires"}
        </h2>
        <div className="flex flex-wrap justify-center pb5">
          {_.times(10, (i) => {
            i++;
            return (
              <div
                // style={{ width: "450px" }}
                className="flex justify-center items-center br3 ba b--black-20 pa2 ma2 shadow-5"
                key={i}
              >
                <span className="pa3 f5">{i}</span>
                <input
                  name={`Tire${i}_32vos`}
                  className={`input-reset br3 ba b--black-20 pa2 w-20`}
                  type="number"
                  onChange={onChange}
                  value={state.walkaround[`Tire${i}_32vos`]}
                  placeholder={`32vos`}
                />
                <input
                  name={`Tire${i}_brand`}
                  className={`input-reset br3 ba b--black-20 pa2 w-40`}
                  type="text"
                  onChange={onChange}
                  value={state.walkaround[`Tire${i}_brand`]}
                  placeholder={`Brand`}
                />
                <input
                  name={`Tire${i}_PSI`}
                  className={`input-reset br3 ba b--black-20 pa2 w-20`}
                  type="number"
                  onChange={onChange}
                  value={state.walkaround[`Tire${i}_PSI`]}
                  placeholder={`PSI`}
                />
              </div>
            );
          })}
        </div>
      </div>
      <DefaultButtons props={{ inSpanish, next, back, save, saveExit }} />
    </>
  );
};

export const DefaultButtons = ({
  props: { inSpanish, next, back, save, saveExit },
}) => {
  return (
    <div className="flex justify-between">
      <button
        className="left b br3 ma3 pr3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
        onClick={back}
      >
        <ArrowLeftIcon />
        {inSpanish ? "Regresar" : "Back"}
      </button>
      <div className="flex justify-center center">
        <button
          className="right b br3 ma3 mr2 pv2 ph3 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
          onClick={saveExit}
        >
          <ExitToAppIcon />
          {inSpanish ? "Guardar y Salir" : "Save & Exit"}
        </button>
        <button
          className="right b br3 ma3 mr2 pv2 ph3 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
          onClick={save}
        >
          <SaveIcon className="pr1" />
          {inSpanish ? "Guardar" : "Save"}
        </button>
        <button
          className="right b br3 ma3 ml1 pl3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
          onClick={next}
        >
          {inSpanish ? "Siguiente" : "Next"}
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  );
};
