import React from "react";

const WAFields = ({ input, inSpanish, inspection, props }) => {
  return (
    <div className="flex flex-wrap flex-grow justify-center">
      {input.name.map((name, i) => {
        return (
          <div key={i}>
            <label htmlFor={input.dbName[i]} className="f4 b db mb2 mt4">
              {inSpanish ? input.spanishName[i] : input.name[i]}
            </label>
            <input
              {...props}
              type={input.type[inSpanish]}
              className={`input-reset f4 br3 ba b--black-20 pa3 mb4 mb2 shadow-5`}
              name={input.dbName[i]}
              value={inspection[input.dbName[i]] || ""}
            />
          </div>
        );
      })}
    </div>
  );
};

export default WAFields;
