import { RECEIVE_CURRENT_USER, LOGOUT_CURRENT_USER } from "../../actions/types";

const initialState = {
  userId: null,
  username: null,
  name: null,
  admin: null,
};

export default (state = initialState, { type, user }) => {
  Object.freeze(state); //prevent changes from existing object
  switch (type) {
    case RECEIVE_CURRENT_USER:
      return user;
    case LOGOUT_CURRENT_USER:
      return initialState;
    default:
      return state;
  }
};
