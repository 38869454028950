import React from "react";
import enLogo from "./enLogo.png";
import esLogo from "./esLogo.png";

const Logo = ({ inSpanish }) => {
  return (
    <div className="flex items-center">
      <img src={inSpanish ? esLogo : enLogo} alt="" className="headImg" />
    </div>
  );
};

export default Logo;
