import { RECEIVE_ERRORS, RECEIVE_SUCCESS, CLEAR_ERRORS } from "./types";

export const receiveErrors = ({ message }) => ({
  type: RECEIVE_ERRORS,
  message,
});

export const receiveSuccess = (message) => ({
  type: RECEIVE_SUCCESS,
  message,
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});
