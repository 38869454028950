import React from "react";
import InspectionCard from "../InspectionCard/InspectionCard";
import CloseIcon from "@material-ui/icons/Close";

const InspectionsModal = ({
  inspections,
  close,
  handleRoute,
  truck_id,
  wa,
}) => {
  inspections = inspections.filter((inspection) =>
    wa === 1
      ? !inspection.completedWA1
      : inspection.completedWA1 && !inspection.completedWA2
  );
  return (
    <div className="modal shadow-3 ma4">
      <div className="ma3" style={{ float: "left" }}>
        <button
          className="center br3 p1 ba white hover-light-red b--white bg-black-40 grow pointer f5"
          onClick={close}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="flex flex-wrap center h-100">
        {inspections
          ? inspections.map((inspection, i) => {
              return (
                <InspectionCard
                  key={i}
                  truckNumber={inspection.truckID.truckNumber}
                  lastEdit={inspection.updatedAt}
                  lastEditor={inspection.lastEditor.name}
                  onClick={() =>
                    handleRoute(`form/${wa}/${truck_id}/${inspection._id}`)
                  }
                />
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default InspectionsModal;
