import { combineReducers } from "redux";
import session from "./session/session";
import trucks from "./trucks/trucks";
import options from "./options/options";
import messages from "./messages/messages";
import users from "./users/users";
import inspections from "./inspections/inspections";

export default combineReducers({
  session,
  users,
  trucks,
  options,
  messages,
  inspections,
});
