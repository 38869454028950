import React, { Component } from "react";
import redX from "./redx.png";
import { connect } from "react-redux";
import { getActiveTrucks } from "../../actions/trucks";
import { inspectionsByTruck } from "../../actions/inspections";
import InspectionsModal from "../InspectionsModal/InspectionsModal";

const mapStateToProps = ({
  trucks,
  options: { inSpanish },
  inspections: { inspections },
}) => ({
  trucks,
  inSpanish,
  inspections,
});

class InspectionMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      truck: "",
      validTruck: false,
      modal: false,
    };
  }

  componentDidMount() {
    this.props.getActiveTrucks();
  }

  truckChange = (event) => {
    this.setState({
      truck:
        this.props.trucks.filter(
          (truck) => truck.truckNumber === event.target.value
        )[0] || "",
      validTruck: this.props.trucks.some(
        (truck) => truck.truckNumber === event.target.value
      ),
    });
  };

  wa2InspectionClick = async () => {
    await this.props.inspectionsByTruck(this.state.truck._id);

    this.setState({ modal: true });
  };

  handleRoute = (route) => {
    this.props.history.push(`/${route}`);
  };

  render() {
    const { trucks, inSpanish } = this.props;
    return (
      <>
        <div>
          <article className="br3 ba bw3 white b--white-20 mv4 mw6 shadow-3 center">
            <main className="pa4 pt0 white-80">
              <div className="pa3 pt0 tc w-100">
                <p className="tc subheader">
                  {inSpanish ? "Menu de Inspecciones" : "Inspection Menu"}
                </p>
                <label className="db fw6 mt5 lh-copy f4 tc" htmlFor="name">
                  {inSpanish ? "Numero de Camion" : "Truck Number"}
                </label>
                <div className="">
                  <input
                    className="di pa2 pr4 input-reset ba black b--white bg-white-50 hover-bg-white"
                    style={{ height: "40px" }}
                    type="text"
                    list="trucks"
                    name="truckNumber"
                    id="truckNumber"
                    placeholder="Choose truck..."
                    onChange={this.truckChange}
                  />
                  {this.state.validTruck ? (
                    ""
                  ) : (
                    <img
                      className="di"
                      src={redX}
                      alt=""
                      width="20px"
                      height="auto"
                      style={{ marginLeft: "-25px", marginBottom: "-7px" }}
                    />
                  )}
                </div>
                <datalist id="trucks">
                  {trucks.map((truck, index) => (
                    <option value={truck.truckNumber} key={index} />
                  ))}
                </datalist>
              </div>

              <div className="tc">
                <button
                  className="b ph3 pv2 input-reset ba white b--white bg-transparent grow pointer f5 dib"
                  disabled={!this.state.validTruck}
                  onClick={() =>
                    this.handleRoute(`wa1-menu/${this.state.truck._id}`)
                  }
                >
                  Walkaround 1
                </button>
                <button
                  className="b ph3 pv2 input-reset ba white b--white bg-transparent grow pointer f5 dib"
                  disabled={!this.state.validTruck}
                  onClick={this.wa2InspectionClick}
                >
                  Walkaround 2
                </button>
              </div>

              <div className="lh-copy mt3 tc pt3">
                <p
                  className="f5 link dim white db pointer underline-hover"
                  onClick={() => this.handleRoute("register-truck")}
                >{`Register New Truck`}</p>
              </div>
            </main>
          </article>
        </div>
        {this.state.modal ? (
          <InspectionsModal
            inspections={this.props.inspections}
            close={() => this.setState({ modal: false })}
            handleRoute={(route) => this.handleRoute(route)}
            truck_id={this.state.truck._id}
            wa={2}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, {
  getActiveTrucks,
  inspectionsByTruck,
})(InspectionMenu);
