import React from "react";
import { Avatar } from "@material-ui/core";

const User = ({ user, clickHandler }) => {
  const style = {
    height: "150px",
  };
  const recentlySignedIn = (date) => {
    let now = new Date();
    now.setMonth(now.getMonth() - 3);
    return now < new Date(date);
  };
  const avatarStyle = {
    width: "60px",
    height: "60px",
    backdropFilter: "blur(5px)",
    backgroundColor: user.active
      ? user.admin
        ? "rgba(255,255,0,0.6)"
        : recentlySignedIn(user.lastActive)
        ? "rgba(0,255,0,0.2)"
        : "rgba(128,128,128,0.2)"
      : "rgba(240, 52, 52, 0.6)",
  };
  return (
    <div
      style={style}
      className="center flex flex-column pointer dim grow pa3"
      onClick={clickHandler}
    >
      <Avatar style={avatarStyle}>
        {user.name
          .split(" ")
          .map((n) => n[0])
          .join("")}
      </Avatar>
      <span className="f4 white">{user.name}</span>
      <span className="f6 white">{user.username}</span>
    </div>
  );
};

export default User;
