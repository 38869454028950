import React from "react";
import { connect } from "react-redux";
import LinkButton from "../../Components/LinkButton/LinkButton";

const mapStateToProps = ({ session, options: { inSpanish } }) => ({
  session,
  inSpanish,
});

const HomePage = ({ inSpanish, session: user }) => {
  return (
    <div>
      <article className="br3 ba bw3 white b--white-20 w-50-l w-70-m w-80-ns w-90 shadow-3 center">
        <main className="w-100 white-80">
          <p className="tc h1 subheader">
            {!inSpanish ? `Welcome, ${user.name}` : `Bienvenido, ${user.name}`}
          </p>
          <p className="tc black-70 f4 fw5">
            {inSpanish ? "Que gustaria hacer?" : "What would you like to do?"}
          </p>
          <div className="tc flex flex-column w-50 center">
            <LinkButton
              className="b br3 ph3 pa3 ba white b--white bg-transparent grow shadow-3 pointer f5 ma3 w-100"
              value={inSpanish ? `Ver Camiones` : `View Trucks`}
              route="trucks"
            />
            <LinkButton
              className="b br3 ph3 pa3 ba white b--white bg-transparent grow shadow-3 pointer f5 ma3 w-100"
              value={inSpanish ? `Ver Inspecciones` : `View Inspections`}
              route="inspections"
            />
            <LinkButton
              className="b br3 ph3 pa3 ba white b--white bg-transparent grow shadow-3 pointer f5 ma3 w-100"
              value={inSpanish ? `Mi Terminal de Trabajo` : `My Workstation`}
              route="workstation"
            />
          </div>
        </main>
      </article>
    </div>
  );
};
export default connect(mapStateToProps)(HomePage);
