import {
  RECEIVE_ERRORS,
  RECEIVE_SUCCESS,
  RECEIVE_CURRENT_USER,
  CLEAR_ERRORS,
} from "../../actions/types";

const initialState = {
  success: "",
  error: "",
};

export default (state = initialState, { type, message }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_ERRORS:
      return {
        ...state,
        error: message,
      };
    case RECEIVE_SUCCESS:
      return {
        ...state,
        success: message,
      };
    case RECEIVE_CURRENT_USER:
    case CLEAR_ERRORS:
      return initialState;
    default:
      return state;
  }
};
