import { RECEIVE_USERS } from "../../actions/types";

const initialState = [];

export default (state = initialState, { type, users }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_USERS:
      return users;
    default:
      return state;
  }
};
