import React from "react";
import { useHistory } from "react-router-dom";

const LinkButton = ({ route, value, className, style, children }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/${route}`);
  };

  return (
    <button
      onClick={handleClick}
      className={className ? className : ""}
      style={style ? style : {}}
    >
      {value || children}
    </button>
  );
};

export default LinkButton;
