import React from "react";
import { Component } from "react";
import Particles from "react-particles-js";
import axios from "axios";
import "./Beta.css";

const particlesOptions = {
  particles: {
    number: {
      value: 15,
      density: {
        enable: true,
        value_area: 200,
      },
    },
  },
};

class Beta extends Component {
  constructor(props) {
    super(props);
    this.state = { route: "menu" };
  }

  routeChange = (route) => {
    this.setState({ route: route });
  };

  /* MailObj
  {
      subject: "",
      body: "",
      sender: "",
  }
  */
  sendMail = async (mailObj) => {
    mailObj.sender = this.props.session.name;
    // console.log(mailObj);
    const response = await axios.post("/api/beta_mail/", mailObj);
    console.log(response.data);
    this.routeChange("menu");
  };

  renderSwitch = () => {
    switch (this.state.route) {
      case "menu":
        return (
          <BetaMenu
            inSpanish={this.props.inSpanish}
            routeChange={this.routeChange}
          />
        );
      case "positive":
        return (
          <PosBeta
            inSpanish={this.props.inSpanish}
            sendMail={this.sendMail}
            routeChange={this.routeChange}
          />
        );
      case "negative":
        return (
          <NegBeta
            inSpanish={this.props.inSpanish}
            sendMail={this.sendMail}
            routeChange={this.routeChange}
          />
        );
      case "suggest":
        return (
          <SugBeta
            inSpanish={this.props.inSpanish}
            sendMail={this.sendMail}
            routeChange={this.routeChange}
          />
        );
      default:
        return (
          <BetaMenu
            inSpanish={this.props.inSpanish}
            routeChange={this.routeChange}
          />
        );
    }
  };
  render() {
    return (
      <>
        <Particles className="particlesBeta" params={particlesOptions} />
        <div className="betaPage">
          <h1 className="f1 yellow tc ma5">Beta Feedback</h1>
          {this.renderSwitch()}
        </div>
      </>
    );
  }
}

const BetaMenu = ({ inSpanish, routeChange }) => {
  return (
    <>
      <div className="center">
        <button
          className="b f3 white pa3 ma4 ba bw3 br4 b--green pointer grow hover-green"
          style={{ background: "rgba(0,0,0,0)" }}
          onClick={() => routeChange("positive")}
        >
          {inSpanish ? "Comentarios" : "Feedback"}
        </button>
        <button
          className="b f3 white pa3 ma4 ba bw3 br4 b--red pointer grow hover-red"
          style={{ background: "rgba(0,0,0,0)" }}
          onClick={() => routeChange("negative")}
        >
          {inSpanish
            ? "Reportar Error en Systema"
            : "Report Errors/Unexpected Behavior"}
        </button>
      </div>
      <button
        className="b f3 white pa3 ma4 ba bw3 br4 b--yellow pointer grow hover-yellow center"
        style={{ background: "rgba(0,0,0,0)" }}
        onClick={() => routeChange("suggest")}
      >
        {inSpanish
          ? "Sugerencias para futuras versiones"
          : "Suggestions for future versions"}
      </button>
      <button
        className="b f3 white pa3 mt6 ba bw3 br4 b--white pointer grow hover-red center"
        style={{ background: "rgba(0,0,0,0)" }}
        onClick={() => window.close()}
      >
        {inSpanish ? "Cerrar" : "Close"}
      </button>
    </>
  );
};

class PosBeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "Feedback",
      body: "",
    };
  }

  onChange = (e) => {
    this.setState({ body: e.target.value });
  };

  render() {
    const { inSpanish, sendMail, routeChange } = this.props;
    return (
      <div className="ba b--green bw3 br4 db">
        <button
          className="floatLeft b f3 white pa3 ba bw3 br4 b--white pointer grow hover-red center"
          style={{ background: "rgba(0,0,0,0)" }}
          onClick={() => routeChange("menu")}
        >
          {"<"}
        </button>
        <textarea
          name="body"
          cols="100"
          rows="20"
          className="b f4 br4 bg-white-75 black center mt3"
          onChange={this.onChange}
          placeholder={
            inSpanish
              ? "Gracias por el commentario..."
              : "Thank you for your feedback..."
          }
        ></textarea>
        <button
          className="b f3 white pa3 ma4 ba bw3 br4 b--green pointer grow hover-green center "
          onClick={() => sendMail(this.state)}
          style={{ background: "rgba(0,0,0,0)" }}
        >
          {inSpanish ? "Enviar" : "Submit"}
        </button>
      </div>
    );
  }
}

class NegBeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "Report Error",
      body: "",
    };
  }

  onChange = (e) => {
    this.setState({ body: e.target.value });
  };

  render() {
    const { inSpanish, sendMail, routeChange } = this.props;
    return (
      <div className="ba b--red bw3 br4 db">
        <button
          className="floatLeft b f3 white pa3 ba bw3 br4 b--white pointer grow hover-red center"
          style={{ background: "rgba(0,0,0,0)" }}
          onClick={() => routeChange("menu")}
        >
          {"<"}
        </button>
        <textarea
          name="body"
          cols="100"
          rows="20"
          className="b f4 br4 bg-white-75 black center mt3"
          placeholder={
            inSpanish
              ? "- Explica claramente el error\n- Explica paso por paso de como llego a ser el error\n-\n-\n-\n-\n\nGracias"
              : "- Explain in detail the error you are seeing\n- Explain step by step how you came onto the described error\n-\n-\n-\n-\n\nThank you"
          }
          onChange={this.onChange}
        ></textarea>
        <button
          className="b f3 white pa3 ma4 ba bw3 br4 b--red pointer grow hover-red center "
          onClick={() => sendMail(this.state)}
          style={{ background: "rgba(0,0,0,0)" }}
        >
          {inSpanish ? "Enviar" : "Submit"}
        </button>
      </div>
    );
  }
}

class SugBeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "Suggestion for future versions",
      body: "",
    };
  }

  onChange = (e) => {
    this.setState({ body: e.target.value });
  };

  render() {
    const { inSpanish, sendMail, routeChange } = this.props;
    return (
      <div className="ba b--yellow bw3 br4">
        <button
          className="floatLeft b f3 white pa3 ba bw3 br4 b--white pointer grow hover-red center"
          style={{ background: "rgba(0,0,0,0)" }}
          onClick={() => routeChange("menu")}
        >
          {"<"}
        </button>
        <textarea
          name="body"
          cols="100"
          rows="20"
          className="b f4 br4 bg-white-75 black center mt3"
          placeholder={
            inSpanish
              ? "- Cualquier sugerencia sera agradecida\n\nGracias"
              : "- Any suggestions will be appreciated\n\nThank you"
          }
          onChange={this.onChange}
        ></textarea>
        <button
          className="b f3 white pa3 ma4 ba bw3 br4 b--yellow pointer grow hover-yellow center"
          onClick={() => sendMail(this.state)}
          style={{ background: "rgba(0,0,0,0)" }}
        >
          {inSpanish ? "Enviar" : "Submit"}
        </button>
      </div>
    );
  }
}

export default Beta;
