import React, { Component } from "react";
import { connect } from "react-redux";
import { getUsers } from "../../actions/users";
import { withRouter } from "react-router-dom";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import LinkButton from "../../Components/LinkButton/LinkButton";
import { Avatar, Tooltip } from "@material-ui/core";
import User from "../../Components/User/User";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const mapStateToProps = ({ options: { inSpanish }, users }) => ({
  inSpanish,
  users,
});

class UsersList extends Component {
  componentDidMount = () => {
    this.props.getUsers();
  };

  handleClick = (route) => {
    this.props.history.push(`/${route}`);
  };

  render() {
    const { inSpanish, users } = this.props;
    const tooltip = (
      <>
        <div className="pa1" style={{ backgroundColor: "rgba(255,255,0,0.7)" }}>
          Admin User
        </div>
        <div className="pa1" style={{ backgroundColor: "rgba(0,255,0,0.3)" }}>
          General User
        </div>
        <div
          className="pa1"
          style={{ backgroundColor: "rgba(128,128,128,0.3)" }}
        >
          No activity in 3 months
        </div>
        <div
          className="pa1"
          style={{ backgroundColor: "rgba(240, 52, 52, 0.7)" }}
        >
          Deactivated
        </div>
      </>
    );
    return (
      <div className="w-60-l w-80-m w-90-ns w-90" style={{ margin: "auto" }}>
        <div
          className="br3 ba bw3 white b--white-20 ma4 shadow-3 flex flex-column"
          style={{ height: "50vh", overflow: "hidden", margin: "auto" }}
        >
          <p
            className="tc subheader pv3 mv0"
            style={{
              backgroundColor: "rgba(255,255,255,0.0)",
              backdropFilter: "blur(5px)",
              marginBottom: "-68px",
              zIndex: 1,
            }}
          >
            {inSpanish ? "Usuarios" : "Users"}
            <Tooltip title={tooltip} interactive>
              <HelpOutlineIcon />
            </Tooltip>
          </p>
          <div
            style={{
              overflowY: "auto",
              display: "flex",
              flexWrap: "wrap",
              flexGrow: 1,
              paddingTop: "68px",
            }}
          >
            <div
              style={{ height: "150px" }}
              className="center flex flex-column pointer dim grow pa5"
              onClick={() => this.handleClick("register-user/")}
            >
              <Avatar
                style={{
                  width: "60px",
                  height: "60px",
                  backgroundColor: "rgba(250, 250, 250, 0.5)",
                }}
              >
                +
              </Avatar>
              <span className="f4">Add User</span>
            </div>
            {users.map((user, i) => {
              return (
                <User
                  key={i}
                  user={user}
                  clickHandler={() =>
                    this.handleClick(`register-user/${user._id}`)
                  }
                />
              );
            })}
          </div>
        </div>
        <div className="left" style={{ float: "left" }}>
          <LinkButton
            className="b br3 ma3 pr3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
            route=""
          >
            <ArrowLeftIcon />
            {inSpanish ? "Regresar" : "Back"}
          </LinkButton>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, { getUsers })(UsersList));
