import React from "react";

const Input = ({
  type = "text",
  inSpanish,
  name,
  labelProps,
  inputProps,
  spLabel,
  enLabel,
}) => {
  return (
    <>
      <label htmlFor={name} className="f4 db mb2 mt3 white tc" {...labelProps}>
        {inSpanish ? spLabel : enLabel}
      </label>
      <input
        type={type}
        name={name}
        className="f4 br3 ba b--black-20 pa3-l pa3-m p2 shadow-5"
        {...inputProps}
      />
    </>
  );
};

export default Input;
