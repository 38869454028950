import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Particles from "react-particles-js";
import { connect } from "react-redux";
import "./App.css";
import Navigation from "../../Components/Navigation/Navigation";
import Alerts from "../../Components/Alerts/Alerts";
import LanguageToggle from "../../Components/LanguageToggle/LanguageToggle";
import Signin from "../../Components/Signin/Signin";
import Register from "../../Components/Register/Register";
import HomePage from "../HomePage/HomePage";
import Users from "../UsersList/UsersList";
import InspectionMenu from "../../Components/InspectionMenu/InspectionMenu";
import WA1Menu from "../../Components/WA1Menu/WA1Menu";
import RegisterTruck from "../../Components/RegisterTruck/RegisterTruck";
import FullWalkaround from "../FullWalkaround/FullWalkaround";
import InspectionList from "../InspectionList/InspectionList";
import Loading from "../../Components/Loading/Loading";
import TruckList from "../TruckList/TruckList";
import { AuthRoute, ProtectedRoute, AdminRoute } from "../../util/route";
import RegisterUser from "../../Components/RegisterUser/RegisterUser";
import Form from "../Form/Form";
import Page404 from "../../Components/Page404/Page404";
import Comment from "../../Components/Comment/Comment";
import Beta from "../../Components/Beta/Beta";

// const particlesOptions = {
//   particles: {
//     number: {
//       value: 15,
//       density: {
//         enable: true,
//         value_area: 200,
//       },
//     },
//   },
// };

const mapStateToProps = ({
  options: { isPending, inSpanish },
  session,
  inspections: { display },
}) => ({
  isPending,
  display,
  session,
  inSpanish,
});

class App extends Component {
  render() {
    const { isPending, display, inSpanish, session } = this.props;
    console.log(inSpanish);
    return (
      <>
        <Switch>
          <ProtectedRoute
            path="/beta"
            component={() => <Beta inSpanish={inSpanish} session={session} />}
          />
          <Route>
            <Particles className="particles" />
            <Route component={Alerts} />
            <Route component={Navigation} />
            {isPending ? <Loading /> : ""}
            {display && !isPending ? <FullWalkaround /> : ""}
            <Switch>
              <AuthRoute path="/signin" component={Signin} />
              <AuthRoute path="/register" component={Register} />
              <AdminRoute path="/users" component={Users} />
              <AdminRoute path="/register-user/:id?" component={RegisterUser} />
              <ProtectedRoute exact path="/" component={HomePage} />
              <ProtectedRoute path="/trucks" component={TruckList} />
              <ProtectedRoute
                path="/register-truck/:id?"
                component={RegisterTruck}
              />
              <ProtectedRoute
                path="/inspections/:truck_id?"
                component={InspectionList}
              />
              <Route path="/comment/:id" component={Comment} />
              <ProtectedRoute path="/workstation" component={InspectionMenu} />
              {/*TODO*/}
              <ProtectedRoute path="/wa1-menu/:truck_id" component={WA1Menu} />
              {/*TODO*/}
              <ProtectedRoute
                path="/form/:walkaround/:truck_id/:id?"
                component={Form}
              />
              <Route component={Page404} />
            </Switch>
          </Route>
          <Route component={LanguageToggle} />
        </Switch>
      </>
    );
  }
}

export default connect(mapStateToProps)(App);
