import React from "react";
import defaultTruck from "./defaultTruck.png";

const Card = ({ truckNumber, model, active, color, image, onClick }) => {
  // const {name, email, id} = props;
  return (
    <div
      className="tc bg-light-blue dib br3 pa3 ma2 grow pointer dim bw2 shadow-5"
      style={{
        backgroundColor: color
          ? color
          : active
          ? "rgba(255,255,255,0.15)"
          : "rgba(240, 0, 0,0.50)",
        backdropFilter: "blur(5px)",
      }}
      onClick={onClick ? onClick : ""}
    >
      <img src={image ? image : defaultTruck} alt="Truck" width="100px" />
      <div>
        <h2>{truckNumber}</h2>
        <p>{model}</p>
      </div>
    </div>
  );
};

export default Card;
