//TEMPLATE

/* 
import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ExportCSV = ({ csvData, fileName, inSpanish }) => {
const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

return (
    <button
    className="b br3 ma3 pr3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
    onClick={(e) => exportToCSV(csvData, fileName)}
    >
    <GetAppIcon />
    {inSpanish ? "Descargar en excel" : "Download to excel"}
    </button>
);
};

 export default ExportCSV; 
 */

import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { buildCSVData } from "../../util/csv";

const ExportCSV = ({ inspections, fileName, inSpanish }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const beginExcelSetup = async (inspections, fileName) => {
    const inspectionIDs = inspections.map((inspection) => inspection._id);
    const csvData = await buildCSVData(inspectionIDs);
    console.log(csvData);
    const date = new Date();
    exportToCSV(
      csvData,
      `Inspections_${date.getDate()}-${
        date.getMonth() - 1
      }-${date.getFullYear()}`
    );
  };

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="b br3 ma3 pr3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
      onClick={(e) => beginExcelSetup(inspections, fileName)}
    >
      <GetAppIcon />
      {inSpanish ? "Descargar en excel" : "Download to excel"}
    </button>
  );
};

export default ExportCSV;
