import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { register } from "../../actions/session";

const mapStateToProps = ({ options: { inSpanish } }) => ({
  inSpanish,
});

const Register = ({ register, inSpanish }) => {
  const onSubmitSignIn = (e) => {
    e.preventDefault();
    const user = {
      [e.target[1].name]: e.target[1].value,
      [e.target[2].name]: e.target[2].value,
      [e.target[3].name]: e.target[3].value,
      [e.target[4].name]: e.target[4].value,
    };
    register(user);
  };
  return (
    <div>
      <article className="br3 ba bw3 white b--white-20 mv4 w-100 w-50-m w-25-l mw6 shadow-3 center">
        <form className="pa4 white-80" onSubmit={onSubmitSignIn}>
          <div className="measure">
            <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
              <legend className="f1 fw6 ph0 mh0 subheader">
                {inSpanish ? "Crear Cuenta" : "Register"}
              </legend>
              <div className="mv3">
                <label className="db fw6 lh-copy f6" htmlFor="name">
                  {inSpanish ? "Nombre Completo" : "Full Name"}
                </label>
                <input
                  className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                  type="text"
                  name="name"
                  id="name"
                  required
                />
              </div>
              <div className="mv3">
                <label className="db fw6 lh-copy f6" htmlFor="username">
                  {inSpanish ? "Usuario" : "Username"}
                </label>
                <input
                  className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                  type="text"
                  name="username"
                  id="username"
                  required
                />
              </div>
              <div className="mv3">
                <label className="db fw6 lh-copy f6" htmlFor="password">
                  {inSpanish ? "Contraseña" : "Password"}
                </label>
                <input
                  className="b pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                  type="password"
                  name="password"
                  id="password"
                  required
                />
              </div>
            </fieldset>
            <div className="">
              <div className="mv3">
                <label className="db fw6 lh-copy f6" htmlFor="registerCode">
                  {inSpanish ? "Codigo de Registracion" : "Register Code"}
                </label>
                <input
                  className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-50"
                  type="registerCode"
                  name="registerCode"
                  id="registerCode"
                  defaultValue="1234"
                  required
                />
              </div>
              <input
                className="b ph3 pv2 input-reset ba white b--white bg-transparent grow pointer f5"
                type="submit"
                value={inSpanish ? "Crear" : "Register"}
              />
              {inSpanish ? " Tiene cuenta? " : " Have an account? "}
              <Link
                to="/signin"
                className="f6 link dim white pointer underline"
              >
                {inSpanish ? "Iniciar session" : "Sign in"}
              </Link>
            </div>
          </div>
        </form>
      </article>
    </div>
  );
};

export default connect(mapStateToProps, { register })(Register);
