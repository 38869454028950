import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getInspection,
  getInspectionForm,
  displayInspection,
  uploadInspection,
} from "../../actions/inspections";
import { Section0, Section1, Section2 } from "./Sections";
import Comments from "./Comments";
import Signature from "./Signature";
import "./Form.css";
import Logo from "../../Components/Logo/Logo";
import { receiveSuccess } from "../../actions/messages";
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walkaround: {
        comments: [],
        Date: new Date(),
        Driver1Name: "",
        Driver1License: "",
        Driver2Name: "",
        Driver2License: "",
        Odometer: "",
        Tire10_PSI: "",
        Tire10_brand: "",
        Tire10_32vos: "",
        Tire1_PSI: "",
        Tire1_brand: "",
        Tire1_32vos: "",
        Tire2_PSI: "",
        Tire2_brand: "",
        Tire2_32vos: "",
        Tire3_PSI: "",
        Tire3_brand: "",
        Tire3_32vos: "",
        Tire4_PSI: "",
        Tire4_brand: "",
        Tire4_32vos: "",
        Tire5_PSI: "",
        Tire5_brand: "",
        Tire5_32vos: "",
        Tire6_PSI: "",
        Tire6_brand: "",
        Tire6_32vos: "",
        Tire7_PSI: "",
        Tire7_brand: "",
        Tire7_32vos: "",
        Tire8_PSI: "",
        Tire8_brand: "",
        Tire8_32vos: "",
        Tire9_PSI: "",
        Tire9_brand: "",
        Tire9_32vos: "",
        signature: "",
      },
      section: 0,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount = async () => {
    //Checks if an inspection id was sent in the url
    if (this.props.match.params.id) {
      await this.props.getInspectionForm(this.props.match.params.id); // getting inspection with id, no comments included
      const { inspection } = this.props;
      !inspection && this.props.history.push("/register-truck/"); //Look into this, why does it move to register-truck if no inspection

      // retrieves walkaround to work on
      const walkaround =
        this.props.match.params.walkaround === "1"
          ? inspection.walkaround1
          : inspection.walkaround2;

      // pass on all existing values to the state
      this.setState({
        walkaround: {
          comments: walkaround.comments || "",
          Date: walkaround.Date || new Date(),
          Driver1Name: walkaround.Driver1Name || "",
          Driver1License: walkaround.Driver1License || "",
          Driver2Name: walkaround.Driver2Name || "",
          Driver2License: walkaround.Driver2License || "",
          Odometer: walkaround.Odometer || "",
          Tire10_PSI: walkaround.Tire10_PSI || "",
          Tire10_brand: walkaround.Tire10_brand || "",
          Tire10_32vos: walkaround.Tire10_32vos || "",
          Tire1_PSI: walkaround.Tire1_PSI || "",
          Tire1_brand: walkaround.Tire1_brand || "",
          Tire1_32vos: walkaround.Tire1_32vos || "",
          Tire2_PSI: walkaround.Tire2_PSI || "",
          Tire2_brand: walkaround.Tire2_brand || "",
          Tire2_32vos: walkaround.Tire2_32vos || "",
          Tire3_PSI: walkaround.Tire3_PSI || "",
          Tire3_brand: walkaround.Tire3_brand || "",
          Tire3_32vos: walkaround.Tire3_32vos || "",
          Tire4_PSI: walkaround.Tire4_PSI || "",
          Tire4_brand: walkaround.Tire4_brand || "",
          Tire4_32vos: walkaround.Tire4_32vos || "",
          Tire5_PSI: walkaround.Tire5_PSI || "",
          Tire5_brand: walkaround.Tire5_brand || "",
          Tire5_32vos: walkaround.Tire5_32vos || "",
          Tire6_PSI: walkaround.Tire6_PSI || "",
          Tire6_brand: walkaround.Tire6_brand || "",
          Tire6_32vos: walkaround.Tire6_32vos || "",
          Tire7_PSI: walkaround.Tire7_PSI || "",
          Tire7_brand: walkaround.Tire7_brand || "",
          Tire7_32vos: walkaround.Tire7_32vos || "",
          Tire8_PSI: walkaround.Tire8_PSI || "",
          Tire8_brand: walkaround.Tire8_brand || "",
          Tire8_32vos: walkaround.Tire8_32vos || "",
          Tire9_PSI: walkaround.Tire9_PSI || "",
          Tire9_brand: walkaround.Tire9_brand || "",
          Tire9_32vos: walkaround.Tire9_32vos || "",
          signature: walkaround.signature || "",
        },
      });
      // else, create a new blank inspection and build url path with inspection id
    } else {
      // Blank walkaround object
      const walkaroundObject = {
        truckID: this.props.match.params.truck_id,
        walkaround1: { comments: [] },
        walkaround2: { comments: [] },
      };
      await this.props.uploadInspection(walkaroundObject); // creating empty WA
      this.props.history.push(
        `/form/${this.props.match.params.walkaround}/${this.props.match.params.truck_id}/${this.props.inspection._id}`
      );
    }
  };

  onChange = (event) => {
    // if statement is necessary because date value is passed differently
    if (event.target) {
      event.persist(); // Weird things happen without this
      this.setState((prevState) => ({
        ...prevState,
        walkaround: {
          ...prevState.walkaround,
          [event.target.name]: event.target.value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        walkaround: {
          ...prevState.walkaround,
          Date: event,
        },
      }));
    }
  };

  next = () => {
    this.setState((prevState) => ({
      ...prevState,
      section: prevState.section + 1,
    }));
    this.save();
  };

  back = () => {
    this.setState((prevState) => ({
      ...prevState,
      section: prevState.section - 1,
    }));
  };

  save = () => {
    const walkaroundObject = {
      _id: this.props.match.params.id || null,
      truckID: this.props.match.params.truck_id,
      walkaround1:
        this.props.match.params.walkaround === "1"
          ? this.state.walkaround
          : this.props.inspection.walkaround1,
      walkaround2:
        this.props.match.params.walkaround === "2"
          ? this.state.walkaround
          : this.props.inspection.walkaround2,
    };
    console.log(walkaroundObject);
    this.props.uploadInspection(walkaroundObject);
  };

  saveExit = () => {
    this.save();
    this.props.history.push("/");
  };

  //display inspection modal
  display = async () => {
    this.props.getInspection(this.props.match.params.id);
    this.props.displayInspection("", true);
  };

  render() {
    const { inSpanish } = this.props;
    const sections = [
      <Section0
        state={this.state}
        inSpanish={inSpanish}
        onChange={this.onChange}
        next={this.next}
        save={this.save}
        saveExit={this.saveExit}
      />,
      <Section1
        state={this.state}
        inSpanish={inSpanish}
        onChange={this.onChange}
        next={this.next}
        save={this.save}
        saveExit={this.saveExit}
        back={this.back}
      />,
      <Comments
        state={this.state}
        inSpanish={inSpanish}
        next={this.next}
        save={this.save}
        saveExit={this.saveExit}
        back={this.back}
      />,
      <Section2
        state={this.state}
        inSpanish={inSpanish}
        onChange={this.onChange}
        next={this.next}
        save={this.save}
        saveExit={this.saveExit}
        back={this.back}
      />,
      <Signature
        state={this.state}
        inSpanish={inSpanish}
        onChange={this.onChange}
        save={this.save}
        back={this.back}
        receiveSuccess={receiveSuccess}
      />,
    ];
    return (
      <div className="formPage">
        <Logo inSpanish={inSpanish} />
        <div
          className="w-100 tc"
          style={{ marginTop: "-75px", marginBottom: "50px" }}
        >
          <button
            className="left b br3 ma3 ph2 pv1 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
            onClick={this.display}
          >
            {inSpanish ? "Mostrar Inspeccion" : "Display Inspection"}
          </button>
        </div>
        {sections[this.state.section]}
      </div>
    );
  }
}

// TODO: Define what msg holds
const mapStateToProps = ({
  options: { inSpanish },
  inspections: { inspectionForm },
  messages: { msg },
}) => ({ inSpanish, inspection: inspectionForm || null, msg });

export default connect(mapStateToProps, {
  getInspection,
  getInspectionForm,
  displayInspection,
  uploadInspection,
  receiveSuccess,
})(Form);
