const {
  RECEIVE_INSPECTIONS,
  RECEIVE_INSPECTION_FORM,
  DISPLAY_INSPECTION,
  RECEIVE_COMMENT,
  CLEAR_COMMENTS,
  REMOVE_COMMENT,
  REMOVE_INSPECTION,
} = require("../../actions/types");

const initialState = {
  inspections: [],
  inspectionForm: {},
  comments: [{}],
  display: false,
  displayInpections: false,
  query: "",
  id: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RECEIVE_INSPECTIONS:
      return { ...state, inspections: payload };
    case REMOVE_INSPECTION:
      const inspections = state.inspections.filter(
        (inspection) => inspection._id !== payload && inspection !== undefined
      );
      return { ...state, inspections };
    case RECEIVE_INSPECTION_FORM:
      return { ...state, inspectionForm: payload };
    case DISPLAY_INSPECTION:
      return {
        ...state,
        ...payload,
      };
    case RECEIVE_COMMENT:
      console.log(payload);
      const newComments = [
        ...state.comments.filter(
          (comment) => comment._id && comment._id !== payload._id
        ),
        payload,
      ];
      console.log(newComments);
      return { ...state, comments: [...newComments] };
    case REMOVE_COMMENT:
      const comments = state.comments.filter(
        (comment) => comment._id !== payload && comment !== undefined
      );
      return { ...state, comments };
    case CLEAR_COMMENTS:
      return { ...state, comments: [] };
    default:
      return state;
  }
};
