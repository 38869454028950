export const inputFields = [
  {
    name: ["Date", "Odometer"],
    spanishName: ["Fecha", "Odometro"],
    dbName: ["Date", "Odometer"],
    type: ["text", "number"],
  },
  {
    name: ["Driver 1 (Name)", "Driver 1 (License)"],
    spanishName: ["Chofer 1 (Nombre)", "Chofer 1 (Licencia)"],
    dbName: ["Driver1Name", "Driver1License"],
    type: ["text", "text"],
  },
  {
    name: ["Driver 2 (Name)", "Driver 2 (License)"],
    spanishName: ["Chofer 2 (Nombre)", "Chofer 2 (Licencia)"],
    dbName: ["Driver2Name", "Driver2License"],
    type: ["text", "text"],
  },
];
