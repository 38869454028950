import React from "react";
import "./LanguageToggle.css";
import { connect } from "react-redux";
import { changeLanguage } from "../../actions/options";

const mapStateToProps = ({ options: { inSpanish } }) => ({
  inSpanish,
});

const LanguageToggle = ({ changeLanguage, inSpanish }) => {
  const changeHandler = (e) => changeLanguage(e.target.value === "spanish");

  return (
    <div
      style={{
        height: "40px",
        marginTop: "50px",
      }}
    >
      <div
        id="radios"
        className="tc mb3"
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <span>
          <input
            type="radio"
            name="language"
            className="radioNoButton language"
            id="english"
            value="english"
            onChange={changeHandler}
            defaultChecked
          />
          <label
            className="labelRadio f4 link dim white pr2 pointer di br b--near-white bw2"
            htmlFor="english"
          >
            {inSpanish ? "Ingles" : "English"}
          </label>
        </span>
        <span>
          <input
            type="radio"
            name="language"
            className="radioNoButton language"
            id="spanish"
            value="spanish"
            onChange={changeHandler}
          />
          <label
            className="labelRadio f4 link dim white pl2 pointer di"
            htmlFor="spanish"
          >
            {inSpanish ? "Español" : "Spanish"}
          </label>
        </span>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, { changeLanguage })(LanguageToggle);
