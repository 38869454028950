export const GET_ERRORS = "GET_ERRORS";
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const RECEIVE_TRUCKS = "RECEIVE_TRUCKS";
export const RECEIVE_INSPECTIONS = "RECEIVE_INSPECTIONS";
export const REMOVE_INSPECTION = "REMOVE_INSPECTION";
export const DISPLAY_INSPECTION = "DISPLAY_ISNPECTION";
export const RECEIVE_COMMENT = "RECEIVE_COMMENT";
export const RECEIVE_INSPECTION_FORM = "RECEIVE_INSPECTION_FORM";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const PENDING_STATUS = "PENDING_STATUS";
export const RECEIVE_ERRORS = "RECEIVE_ERRORS";
export const RECEIVE_SUCCESS = "RECEIVE_SUCCESS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
