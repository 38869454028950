import * as apiUtil from "../util/users";
import { RECEIVE_USERS } from "./types";
import { receiveErrors, receiveSuccess } from "./messages";
import { pendingResponse } from "./pending";

const receiveUsers = (users) => ({
  type: RECEIVE_USERS,
  users,
});

export const getUsers = () => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.getUsers();
    dispatch(receiveUsers(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.getUser(id);
    dispatch(receiveUsers(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const uploadUser = (user = null) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response =
      user._id === ""
        ? await apiUtil.register(user)
        : await apiUtil.updateUser(user);
    dispatch(receiveSuccess(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};
