import React, { Component } from "react";
import Scroll from "../Scroll/Scroll";
import WAFields from "../WAFields/WAFields";
import "./Walkaround.css";
import _ from "lodash";
import { inputFields } from "../../util/inputList.js";

class WalkaroundForm extends Component {
  openComment = (id) => {
    window.open(`https://walkaround.jorgebarrueta.me/comment/${id}`, "_blank");
  };

  render() {
    const { walkaround, inSpanish, inspection } = this.props;
    if (inspection["Date"]) {
      const date = new Date(inspection["Date"]);
      console.log(date);
      const formattedDate = `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
      inspection["Date"] = formattedDate;
    }
    return (
      <Scroll>
        <div style={{ zoom: "70%" }}>
          <h1 className="b f3 tc pt5">
            {walkaround === "walkaround1" && inSpanish
              ? "Entrega de Troca"
              : walkaround === "walkaround2" && inSpanish
              ? "Recepcion de Troca"
              : walkaround === "walkaround1"
              ? "Walkaround 1"
              : "Walkaround 2"}
          </h1>
          <div className="tc mt5">
            {inputFields.map((input, i) => (
              <WAFields
                inSpanish={inSpanish}
                inspection={inspection}
                input={input}
                props={{ readOnly: true }}
                key={i}
              />
            ))}
          </div>
          {inspection.comments.map((comment, i) => (
            <div
              className="br3 ba b--black-20 pa4 pt0 ma2 shadow-5 grow pointer"
              key={i}
              onClick={() => this.openComment(comment._id)}
            >
              <div className="flex justify-between">
                <p className="f5">{"Comment " + i}</p>
              </div>

              <div className="flex justify-between items-center ">
                <textarea
                  className="center"
                  name={"comment" + i}
                  style={{ width: "100%", height: "250px" }}
                  readOnly={true}
                  value={comment.comment}
                ></textarea>
                <div
                  className="center pl2"
                  style={{ width: "100%", height: "300px" }}
                >
                  <img
                    src={comment.image}
                    alt=""
                    style={{
                      width: "90%",
                      height: "90%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
            </div>
          ))}

          <div
            // style={{ minHeight: "200px" }}
            className="bb bw3 bw5 b--white-30 pb5"
          >
            <p className="b f4 tc pt5">{inSpanish ? "Llantas" : "Tires"}</p>
            <div className="flex w-50 center justify-center items-center br3 ba b--black-20 pa2 ma2 shadow-5">
              <span className="pa3 f5"> </span>
              <span className={`ph5 w-20`}>32vos</span>
              <span className={`ph4`}>Tire Brand</span>
              <span className={`ph5 w-20`}>PSI</span>
            </div>
            <div className="flex flex-wrap justify-center pb5">
              {_.times(10, (i) => {
                i++;
                return (
                  <div
                    className="flex justify-center items-center br3 ba b--black-20 pa2 ma2 shadow-5"
                    key={i}
                  >
                    <span className="pa3 f5">{i}</span>
                    <input
                      name={`Tire${i}_32vos`}
                      className={`input-reset br3 ba b--black-20 pa2 w-20`}
                      type="number"
                      readOnly={true}
                      value={inspection[`Tire${i}_32vos`] || ""}
                    />
                    <input
                      name={`Tire${i}_brand`}
                      className={`input-reset br3 ba b--black-20 pa2 w-40`}
                      type="text"
                      readOnly={true}
                      value={inspection[`Tire${i}_brand`] || ""}
                    />
                    <input
                      name={`Tire${i}_PSI`}
                      className={`input-reset br3 ba b--black-20 pa2 w-20`}
                      type="number"
                      readOnly={true}
                      value={inspection[`Tire${i}_PSI`] || ""}
                    />
                  </div>
                );
              })}
            </div>
            <div className="tc">
              <p className="f2 b white">Driver Seat 1 Signature</p>
              <img src={inspection.signature} alt="signature" />
            </div>
          </div>
        </div>
      </Scroll>
    );
  }
}

export default WalkaroundForm;
