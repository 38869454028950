import React from "react";
import defaultInspection from "./defaultInspection.png";
import { Paper } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

const InspectionCard = ({
  id,
  inSpanish,
  truckNumber,
  completed,
  lastEdit,
  lastEditor,
  onClick,
  deleteInspection,
  admin,
}) => {
  const deleteConfirm = (id) => {
    const deleteConfirmation = window.confirm(
      inSpanish
        ? "Seguro que quiere borrar esta inspeccion?\nNo abra manera de recuperarla."
        : "Are you sure you want to permanently delete this inspection?\nThis will be irrevesible."
    );
    deleteConfirmation && deleteInspection(id);
  };

  return (
    <div
      className="ma3 pb0 relative"
      style={{
        width: "300px",
        height: "300px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        color: "#FFF",
      }}
    >
      {admin && (
        <button
          className="pointer ma0 grow hover-light-red"
          style={{
            float: "right",
            zIndex: 1,
            position: "relative",
            marginTop: "15px",
            marginRight: "-16px",
          }}
          onClick={() => deleteConfirm(id)}
        >
          <DeleteIcon />
        </button>
      )}
      <Paper
        className="f4 ma3 pb0 relative dim pointer grow"
        style={{
          backgroundImage: `url(${defaultInspection})`,
          backdropFilter: "blur(50px)",
          width: "300px",
          height: "300px",
          backgroundPosition: "center",
          backgroundSize: "cover",
          color: "#FFF",
        }}
        onClick={onClick}
      >
        <div
          className="tc b f3 w-100 pv3 mt0"
          style={{
            backgroundColor: completed
              ? "rgba(0,255,0,0.5)"
              : "rgba(255,0,0,0.5)",
          }}
        >
          {truckNumber}
        </div>
        <div className="bg-black-80 f5 mb0 pl1 w-100 absolute bottom-0">
          <p>
            {inSpanish ? "ultimo cambio " : "last edit "}
            <strong>{moment(lastEdit).fromNow()}</strong>
          </p>
          <p>
            {inSpanish ? "hecho por " : "by "}
            <strong>{lastEditor}</strong>
          </p>
        </div>
      </Paper>
    </div>
  );
};

export default InspectionCard;
