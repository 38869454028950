import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import DateInput from "../DateInput/DateInput";
import DataListDrivers from "../DataLists/DataListDrivers";
import DataListTrucks from "../DataLists/DataListTrucks";
import { Checkbox } from "@material-ui/core";

class FilterModal extends Component {
  initialState = {
    truckID: "",
    dateRangeType: "updatedAt",
    startDate: new Date(
      "Wed Jan 01 2020 00:00:00 GMT-0600 (Central Standard Time)"
    ),
    endDate: new Date(new Date().setHours(0, 0, 0, 0)),
    lastEditor: "",
    completedWA1: null,
    completedWA2: null,
  };
  constructor(props) {
    super(props);
    this.state = this.initialState;
    console.log(this.state);
  }

  onChange = async (e) => {
    console.log(e);
    if (e.target) {
      await this.setState({ [e.target.name]: e.target.value });
    } else {
      await this.setState({ [e.name]: e.value });
    }
    console.log(this.state);
  };

  toggleCheckboxes = async (e) => {
    console.log(e);
    e.persist();
    await this.setState((prevState) => ({
      [e.target.name]: !prevState[e.target.name],
    }));
    console.log(this.state);
  };

  filter = () => {
    if (this.state !== this.initialState) {
      const query = this.buildFilterObject();
      this.props.getInspections(query);
      // Changes the state in inspection list to true to display the clear filter button
      this.props.activateFilter(query);
    }
    this.props.close();
  };

  buildFilterObject = () => {
    const filterObject = {
      [`${this.state.dateRangeType}[$gte]`]: `${this.state.startDate.getFullYear()}-${
        this.state.startDate.getMonth() + 1
      }-${this.state.startDate.getDate()}`,
      [`${this.state.dateRangeType}[$lte]`]: `${this.state.endDate.getFullYear()}-${
        this.state.endDate.getMonth() + 1
      }-${this.state.endDate.getDate() + 1}T07:00:00.000+00:00`,
    };
    this.state.truckID && (() => (filterObject.truckID = this.state.truckID))();
    this.state.lastEditor &&
      (() => (filterObject.lastEditor = this.state.lastEditor))();
    console.log(filterObject);
    this.state.completedWA1 &&
      (() => (filterObject.completedWA1 = this.state.completedWA1))();
    this.state.completedWA2 &&
      (() => (filterObject.completedWA2 = this.state.completedWA2))();
    const query = this.createQueryString(filterObject);
    console.log(query);
    return `?${query}`;
  };

  createQueryString = (data) => {
    const query = Object.keys(data)
      .map((key) => {
        let val = data[key];
        console.log(val);
        if (typeof val === "object" && !(val instanceof Date)) {
          console.log("recursive");
          val = this.createQueryString(val);
        } else {
          val = encodeURIComponent(`${val}`.replace(/\s/g, "_"));
          console.log(val);
        }
        return `${key}=${val}`;
      })
      .join("&");
    console.log(query);
    return query;
  };

  render() {
    const { inSpanish, close } = this.props;
    return (
      <div className="modal shadow-3 ma4">
        <div className="ma3" style={{ float: "right" }}>
          <button
            className="center br3 p1 ba white hover-light-red b--white bg-black-40 grow pointer f5"
            onClick={close}
          >
            <CloseIcon />
            {inSpanish ? "Cancelar" : "Cancel"}
          </button>
        </div>
        <div className="mt6">
          <h1 className="h1 white center ma0 pb4 bb b--white bw3">
            {inSpanish ? "Filtrar por..." : "Filter by..."}
          </h1>
          <div className="center mt4">
            <span className="f3 white bb b--white bw2">
              {inSpanish ? "Filtrar fechas por:" : "Filter Dates by:"}
            </span>
            <div className="mh4">
              <input
                type="radio"
                className="mh3 pv2 ph3"
                name="dateRangeType"
                value="walkaround1.Date"
                onChange={this.onChange}
              />
              <label htmlFor="dateRangeType" className="f3 white">
                Walkaround 1
              </label>
            </div>
            <div className="mh4">
              <input
                type="radio"
                className="mh3 pv2 ph3"
                name="dateRangeType"
                value="walkaround2.Date"
                onChange={this.onChange}
              />
              <label htmlFor="dateRangeType" className="f3 white">
                Walkaround 2
              </label>
            </div>
            <div className="mh4">
              <input
                type="radio"
                className="mh3 pv2 ph3"
                name="dateRangeType"
                value="createdAt"
                onChange={this.onChange}
              />
              <label htmlFor="dateRangeType" className="f3 white">
                {inSpanish ? "Fecha de Creacion" : "Creation Date"}
              </label>
            </div>
            <div className="mh4">
              <input
                type="radio"
                className="mh3 pv2 ph3"
                name="dateRangeType"
                value="updatedAt"
                onChange={this.onChange}
                defaultChecked
              />
              <label htmlFor="dateRangeType" className="f3 white">
                {inSpanish ? "Ultima Edicion" : "Last Edit"}
              </label>
            </div>
          </div>
          <div className="center mt5 pb4 bb b--white-50 bw2">
            <DateInput
              onChange={this.onChange}
              inputClass="mh3 pv2 ph3 bg-white br1"
              name="startDate"
              defaultDate={new Date("01/01/2020")}
            />
            <label className="f3 white mh5">to</label>
            <DateInput
              onChange={this.onChange}
              inputClass="mh3 pv2 ph3 bg-white br1"
              name="endDate"
              defaultDate={new Date()}
            />
          </div>
          <div className="center pt1" style={{ display: "flex" }}>
            <div className="pr4 ">
              <div className="center mt4">
                <label htmlFor="truck" className="f3 white">
                  {inSpanish ? "Numero Camion:" : "Truck Number:"}
                </label>
                <DataListTrucks
                  name="truckID"
                  inputClass="ml3 pv2 ph3 mr5"
                  onChange={this.onChange}
                />
              </div>
              <div className="center mt5">
                <label htmlFor="lastEditor" className="f3 white">
                  {inSpanish ? "Ultimo Editor:" : "Last Editor:"}
                </label>
                <DataListDrivers
                  name="lastEditor"
                  inputClass="mh3 pv2 ph3"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="f3 white mt3 pl4 bl b--white-50 bw2">
              <div className="center pv3">
                <Checkbox
                  name={"completedWA1"}
                  style={{ color: "white" }}
                  onChange={this.toggleCheckboxes}
                />
                <label htmlFor="completedWA1">
                  {inSpanish
                    ? "Walkaround 1 Completo"
                    : "Walkaround 1 Completed"}
                </label>
              </div>
              <div className="center pv3">
                <Checkbox
                  name={"completedWA2"}
                  style={{ color: "white" }}
                  onChange={this.toggleCheckboxes}
                />
                <label htmlFor="completedWA2">
                  {inSpanish
                    ? "Walkaround 2 Completo"
                    : "Walkaround 2 Completed"}
                </label>
              </div>
            </div>
          </div>
        </div>
        <button
          className="f3 ma4 br4 ph2 pv1 b-white p1 ba white hover-light-blue b--white bg-black-40 grow pointer"
          onClick={this.filter}
          style={{ float: "right" }}
        >
          {inSpanish ? "Filtrar" : "Set Filter(s)"}
        </button>
      </div>
    );
  }
}

export default FilterModal;
