import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function DateInput({ onChange, inputClass, name, defaultDate }) {
  const [selectedDate, setSelectedDate] = React.useState(defaultDate);
  const handleDateChange = ({ date, name }) => {
    setSelectedDate(date);
    onChange({ value: date, name });
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={inputClass}>
        <KeyboardDatePicker
          name={name}
          margin="normal"
          format="MM/dd/yyyy"
          value={selectedDate}
          onChange={(date) => handleDateChange({ date, name })}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}
