import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Provider as AlertProvider } from "react-alert";
import { BrowserRouter } from "react-router-dom";
import AlertTemplate from "react-alert-template-basic";
import "./index.css";
import App from "./Containers/App/App";
import configureStore from "./store/store";
import { checkLoggedIn } from "./util/session";
//import * as serviceWorker from "./serviceWorker";
import "tachyons";

// Alert Options
const alertOptions = {
  timeout: 10000,
  position: "top center",
};
const renderApp = (preloadedState) => {
  const store = configureStore(preloadedState);
  ReactDOM.render(
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AlertProvider>
    </Provider>,
    document.getElementById("root")
  );
};

(async () => renderApp(await checkLoggedIn()))();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
