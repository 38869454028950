import React, { Component } from "react";
import { connect } from "react-redux";
import { getUsers } from "../../actions/users";

class DataListDrivers extends Component {
  componentDidMount() {
    this.props.getUsers();
  }

  userChange = (event) => {
    const userID =
      this.props.users.filter((user) => user.name === event.target.value)[0]
        ._id || null;
    this.props.onChange({ name: event.target.name, value: userID });
  };

  render() {
    const { users, inputClass, name } = this.props;
    return (
      <>
        <div className="">
          <input
            className={inputClass}
            style={{ height: "40px" }}
            type="text"
            list="users"
            name={name}
            placeholder="Optional"
            onChange={this.userChange}
          />
        </div>
        <datalist id="users">
          {users.map((user, index) => (
            <option value={user.name} key={index} />
          ))}
        </datalist>
      </>
    );
  }
}

export default connect(({ users }) => ({ users }), { getUsers })(
  DataListDrivers
);
