import React, { Component } from "react";
import { connect } from "react-redux";
import Truck from "../../Components/Truck/Truck";
import { getTrucks } from "../../actions/trucks";
import { withRouter } from "react-router-dom";
import LinkButton from "../../Components/LinkButton/LinkButton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

const mapStateToProps = ({ trucks, options: { inSpanish } }) => ({
  trucks,
  inSpanish,
});

class TruckList extends Component {
  componentDidMount() {
    this.props.getTrucks();
  }

  handleClick = (route) => {
    this.props.history.push(`/${route}`);
  };

  render() {
    const { trucks, inSpanish } = this.props;
    return (
      <div className="w-60-l w-80-m w-90-ns w-90 " style={{ margin: "auto" }}>
        <div
          className="br3 ba bw3 white b--white-20 ma4 shadow-3 flex flex-column"
          style={{ height: "50vh", overflow: "hidden", margin: "auto" }}
        >
          <p
            className="tc subheader pv3 mv0"
            style={{
              backgroundColor: "rgba(255,255,255,0.0)",
              backdropFilter: "blur(5px)",
              marginBottom: "-68px",
              zIndex: 1,
            }}
          >
            {inSpanish ? "Mis Camiones" : "My Trucks"}
          </p>
          <div
            style={{
              overflowY: "auto",
              display: "flex",
              flexWrap: "wrap",
              flexGrow: 1,
              justifyContent: "center",
              paddingTop: "68px",
            }}
          >
            <Truck
              truckNumber="+ Truck"
              model=""
              color="#9DB594"
              onClick={() => this.handleClick("register-truck")}
            />
            {trucks.map((truck, i) => {
              return (
                <Truck
                  key={i}
                  truckNumber={truck.truckNumber}
                  model={truck.model}
                  active={truck.active}
                  image={truck.image}
                  onClick={() => {
                    return this.handleClick(`register-truck/${truck._id}`);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="left" style={{ float: "left" }}>
          <LinkButton
            className="b br3 ma3 pr3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
            route=""
          >
            <ArrowLeftIcon />
            {inSpanish ? "Regresar" : "Back"}
          </LinkButton>
        </div>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, { getTrucks })(TruckList));
