import React, { Component } from "react";
import { connect } from "react-redux";
import { getTrucks } from "../../actions/trucks";

class DataListTrucks extends Component {
  componentDidMount() {
    this.props.getTrucks();
  }

  truckChange = (event) => {
    const possibleTrucks = this.props.trucks.filter(
      (truck) => truck.truckNumber === event.target.value
    );
    const truckID = possibleTrucks.length > 0 ? possibleTrucks[0]._id : null;
    this.props.onChange({ name: event.target.name, value: truckID });
  };

  render() {
    const { trucks, inputClass, name } = this.props;
    console.log(trucks);
    return (
      <>
        <div className="">
          <input
            className={inputClass}
            style={{ height: "40px" }}
            type="text"
            list="trucks"
            name={name}
            placeholder="Optional"
            onChange={this.truckChange}
          />
        </div>
        <datalist id="trucks">
          {trucks.map((truck, index) => (
            <option value={truck.truckNumber} key={index} />
          ))}
        </datalist>
      </>
    );
  }
}

export default connect(({ trucks }) => ({ trucks }), { getTrucks })(
  DataListTrucks
);
