import React from "react";
import "./Navigation.css";
import Logo from "../Logo/Logo";
import { connect } from "react-redux";
import { logout } from "../../actions/session";
import { Link, NavLink, useHistory } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import { useMediaQuery } from "@material-ui/core";
import SideMenu from "../SideMenu/SideMenu";
import HomeIcon from "@material-ui/icons/Home";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentIcon from "@material-ui/icons/Assignment";

const mapStateToProps = ({ session, options: { inSpanish } }) => ({
  session,
  inSpanish,
});

const AuthNav = ({ inSpanish }) => (
  <nav className="flex justify-between pa4 mb4 navigation">
    <div className="logo di">
      <Logo inSpanish={inSpanish} />
    </div>
    <div>
      <NavLink
        to="/signin"
        className="f3 link dim white pa3 pb1 mb2 pointer di"
        activeClassName="bb bw2 b--near-white"
      >
        {inSpanish ? "Iniciar Session" : "Sign In"}
      </NavLink>
      <NavLink
        to="/register"
        className="f3 link dim white pa3 pb1 mb2 pointer di"
        activeClassName="bb bw2 b--near-white"
      >
        {inSpanish ? "Registrar" : "Register"}
      </NavLink>
    </div>
  </nav>
);

const SessionNav = ({ inSpanish, admin, logout }) => {
  return (
    <nav className="flex pa4 mb4 navigation">
      <div
        className="logo br b--near-white bw2"
        style={{ paddingRight: "20px" }}
      >
        <Logo inSpanish={inSpanish} />
      </div>
      <div className="center" style={{ marginLeft: "20px" }}>
        <NavLink
          exact
          to="/"
          className="f3 link dim white pa3 pb1 mb2 pointer di"
          activeClassName="b"
        >
          {inSpanish ? "Inicio" : "Home"}
        </NavLink>
        {admin && (
          <NavLink
            exact
            to="/users"
            className="f3 link dim white pa3 pb1 mb2 pointer di"
            activeClassName="b"
          >
            {inSpanish ? "Usuarios" : "Users"}
          </NavLink>
        )}
        <NavLink
          exact
          to="/trucks"
          className="f3 link dim white pa3 pb1 mb2 pointer di"
          activeClassName="b"
        >
          {inSpanish ? "Camiones" : "Trucks"}
        </NavLink>
        <NavLink
          exact
          to="/inspections"
          className="f3 link dim white pa3 pb1 mb2 pointer di"
          activeClassName="b"
        >
          {inSpanish ? "Inspecciones" : "Inspections"}
        </NavLink>
        <Link
          exact
          to="/beta"
          className="b f3 link dim yellow pa3 pb1 mb2 ml3 pointer di"
          activeClassName="b"
          target="_blank"
        >
          Beta
        </Link>
      </div>
      <div>
        <LockIcon
          className="red pointer grow dim"
          onClick={() => {
            logout();
          }}
        >
          {inSpanish ? "Cerrar Session" : "Sign Out"}
        </LockIcon>
      </div>
    </nav>
  );
};

const MobileNav = ({ admin, inSpanish, logout }) => {
  const history = useHistory();
  const to = (route) => history.push(route);
  const list = [
    { text: "Home", icon: <HomeIcon />, onClick: () => to("/") },
    admin && {
      text: "Users",
      icon: <AccountBoxIcon />,
      onClick: () => to("/users"),
    },
    {
      text: "Trucks",
      icon: <LocalShippingIcon />,
      onClick: () => to("/trucks"),
    },
    {
      text: "Inspections",
      icon: <AssignmentIcon />,
      onClick: () => to("/inspections"),
    },
  ];
  const bottom = [
    {
      text: <span className="f5 red">Logout</span>,
      icon: <LockIcon className="red" />,
      onClick: logout,
    },
  ];
  return (
    <nav className="flex justify-between pa4 mb4 navigation">
      <div className="logo di">
        <Logo inSpanish={inSpanish} />
      </div>
      <SideMenu elements={list} bottom={bottom} />
    </nav>
  );
};

const Navigation = ({ inSpanish, session, logout }) => {
  const isSignedIn = session.userId !== undefined && session.userId !== null;
  const mobile = useMediaQuery("(max-width: 500px)");

  return (
    <>
      {isSignedIn ? (
        mobile ? (
          <MobileNav
            admin={session.admin}
            inSpanish={inSpanish}
            logout={logout}
          />
        ) : (
          <SessionNav
            admin={session.admin}
            inSpanish={inSpanish}
            logout={logout}
          />
        )
      ) : (
        <AuthNav inSpanish={inSpanish} />
      )}
    </>
  );
};

export default connect(mapStateToProps, { logout })(Navigation);
