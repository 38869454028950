import React, { Component } from "react";
import { connect } from "react-redux";
import "./WA1Menu.css";
import InspectionsModal from "../InspectionsModal/InspectionsModal";
import { getTruck } from "../../actions/trucks";
import { inspectionsByTruck } from "../../actions/inspections";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

const mapStateToProps = ({
  options: { inSpanish },
  trucks,
  inspections: { inspections },
}) => ({
  inSpanish,
  truck: trucks[0] || {},
  inspections,
});

class WA1Menu extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }

  componentDidMount() {
    this.props.getTruck(this.props.match.params.truck_id);
    this.props.inspectionsByTruck(this.props.match.params.truck_id);
  }

  createNew = () => {
    this.props.history.push(`/form/1/${this.props.truck._id}`);
  };

  handleRoute = (route) => {
    this.props.history.push(`/${route}`);
  };

  back = () => {
    this.props.history.goBack();
  };

  render() {
    const { inSpanish, truck } = this.props;
    return (
      <>
        <div
          className="br3 ba bw3 white b--white-20 shadow-3 w-50-l w-70-m w-80-ns w-90"
          style={{ margin: "auto" }}
        >
          <p className="tc h1 f2">Walkaround 1 Menu</p>
          <p className="tc black f4">
            {inSpanish ? `Para camion ` : `For truck `}
            <span className="white">{truck.truckNumber || ""}</span>
          </p>
          <div className="tc flex flex-column w-50 center">
            <button
              className="b br3 ph3 pa3 ba white b--white bg-transparent grow shadow-3 pointer f5 ma3 w-100"
              onClick={() => this.createNew()}
            >
              {inSpanish ? `Crear Nuevo WA1` : `Create New WA1`}
            </button>
            <button
              className="b br3 ph3 pa3 ba white b--white bg-transparent grow shadow-3 pointer f5 ma3 w-100"
              onClick={() => this.setState({ modal: true })}
            >
              {inSpanish ? `Continuar WA1 Existente` : `Continue Existing WA1`}
            </button>
          </div>
          <div className="pb4 pr4 di" style={{ float: "left" }}>
            <button
              className="b br3 ma3 pr3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
              onClick={() => this.back()}
            >
              <ArrowLeftIcon />
              {inSpanish ? "Regresar" : "Back"}
            </button>
          </div>
        </div>
        {this.state.modal ? (
          <InspectionsModal
            inspections={this.props.inspections}
            close={() => this.setState({ modal: false })}
            handleRoute={(route) => this.handleRoute(route)}
            truck_id={truck._id}
            wa={1}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, {
  getTruck,
  inspectionsByTruck,
})(WA1Menu);
