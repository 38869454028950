import { CHANGE_LANGUAGE, PENDING_STATUS } from "../../actions/types";

const initialState = {
  inSpanish: false,
  isPending: false,
};

export default (state = initialState, { type, inSpanish, isPending }) => {
  Object.freeze(state); //prevent changes from existing object
  switch (type) {
    case CHANGE_LANGUAGE:
      return { ...state, inSpanish };
    case PENDING_STATUS:
      return { ...state, isPending };
    default:
      return state;
  }
};
