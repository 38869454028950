import React, { Component } from "react";
import SignatureCanvas from "react-signature-canvas";
import DoneIcon from "@material-ui/icons/Done";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import SaveIcon from "@material-ui/icons/Save";
import { withRouter } from "react-router-dom";
import "./Style.css";

class Signature extends Component {
  componentDidMount() {
    this.sigPad.fromDataURL(this.props.state.walkaround.signature);
  }

  clearSignature = () => {
    this.sigPad.clear();
  };

  done = () => {
    console.log("Done");
    this.props.state.walkaround.signature = this.sigPad
      .getTrimmedCanvas()
      .toDataURL();
    this.props.save();
    this.props.receiveSuccess("Inspeccion completed!");
    this.props.history.push("/workstation");
    this.props.receiveSuccess(
      `Walkaround ${this.props.match.params.walkaround}`
    );
  };

  render() {
    const { inSpanish } = this.props;
    return (
      <>
        <div
          className="flex justify-center items-center"
          style={{ height: "60%", overflow: "auto" }}
        >
          <div>
            <div
              className="bg-white shadow-4 br3 dib tr signature"
              style={{ width: "500px", height: "300px" }}
            >
              <SignatureCanvas
                ref={(ref) => {
                  this.sigPad = ref;
                }}
                canvasProps={{
                  width: 500,
                  height: 300,
                  id: "signature",
                }}
              />
            </div>
            <button
              className="b ph3 pv2 ba white b--white bg-transparent grow pointer f5 db"
              onClick={() => this.clearSignature()}
            >
              Clear
            </button>
          </div>
        </div>
        <DefaultButtons
          props={{
            inSpanish,
            done: this.done,
            back: this.props.back,
            save: this.props.save,
          }}
        />
      </>
    );
  }
}

const DefaultButtons = ({ props: { inSpanish, done, back, save } }) => {
  return (
    <div className="flex justify-between">
      <button
        className="left b br3 ma3 pr3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
        onClick={back}
      >
        <ArrowLeftIcon />
        {inSpanish ? "Regresar" : "Back"}
      </button>
      <div className="flex justify-center center">
        <button
          className="right b br3 ma3 mr2 pv2 ph3 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
          onClick={save}
        >
          <SaveIcon className="pr1" />
          {inSpanish ? "Guardar" : "Save"}
        </button>
        <button
          className="right b br3 ma3 ml1 pl3 pv2 ba white hover-light-red b--white bg-black-40 grow pointer f5 flex items-center"
          onClick={done}
        >
          {inSpanish ? "Listo!" : "Done"}
          <DoneIcon />
        </button>
      </div>
    </div>
  );
};

export default withRouter(Signature);
