import axios from "axios";
export const getInspections = (query) => axios.get(`/api/inspections${query}`);

export const inspectionsByTruck = (truck_id) =>
  axios.get(`/api/inspections?truckID=${truck_id}`);

export const saveInspection = (inspection) =>
  axios.post("/api/inspections/", { ...inspection });

export const deleteInspection = (id) => axios.delete(`/api/inspection/${id}`);

export const getInspection = (id) => axios.get(`/api/inspection/${id}`);

export const getInspectionForm = (id) =>
  axios.get(`/api/inspection/form/${id}`);

export const saveComment = (comment) =>
  axios.post("/api/comment/", { ...comment });

export const deleteComment = (id) => axios.delete(`/api/comment/${id}`);

export const populateComment = (id) => axios.get(`/api/comment/${id}`);
