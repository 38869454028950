const { RECEIVE_TRUCKS } = require("../../actions/types");

const initialState = [];

export default (state = initialState, { type, listTrucks }) => {
  switch (type) {
    case RECEIVE_TRUCKS:
      return listTrucks;
    default:
      return state;
  }
};
