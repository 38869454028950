import axios from "axios";

export const signin = (user) => axios.post("/api/signin", { ...user });

export const register = (user) => axios.post("/api/register", { ...user });

export const logout = () => axios.delete("/api/logout");

export const checkLoggedIn = async (preloadedState) => {
  const response = await axios.get("/api/session");
  const { user } = response.data;
  preloadedState = user ? { session: user } : {};
  return preloadedState;
};
