import React, { Component } from "react";
import extend from "./extend.png";

class Scroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  expandButton = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  render(props) {
    const { expanded } = this.state;
    return (
      <div
        style={{
          overflowY: "auto",
          height: "70%",
          background: "#D8D9DE",
          margin: "20px auto",
          width: expanded ? "100%" : "49%",
        }}
        className="br4 ma4"
      >
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "-30px",
            marginRight: "-30px",
          }}
        >
          <img
            className={`grow pointer absolute ${
              expanded ? "bg-light-green" : "bg-white-40"
            } pa3 br-100 ba b--black-50`}
            src={extend}
            alt=""
            onClick={() => this.expandButton()}
          />
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default Scroll;
