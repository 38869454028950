import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ListItemIcon, ListItemText } from "@material-ui/core";

const useStyles = makeStyles({
  list: {
    width: 250,
    backgroundColor: "#333",
    color: "white",
    height: "100%",
  },
});

const SideMenu = ({ elements, bottom }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open });
  };

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {elements.map((element, index) => (
          <>
            <ListItem button key={index} onClick={element.onClick}>
              <ListItemIcon>{element.icon}</ListItemIcon>
              <ListItemText primary={element.text} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
      {bottom && (
        <List
          style={{
            bottom: "0px",
            position: "absolute",
            padding: "50px",
            width: "100%",
          }}
        >
          {bottom.map((element, index) => (
            <ListItem button key={index} onClick={element.onClick}>
              <ListItemIcon>{element.icon}</ListItemIcon>
              <ListItemText primary={element.text} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );

  return (
    <>
      <Button onClick={toggleDrawer(true)}>
        <MoreVertIcon style={{ zoom: "200%" }} />
      </Button>
      <Drawer anchor="right" open={state.open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};

export default SideMenu;
