import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";

const mapStateToProps = ({ session: { userId, admin } }) => ({
  loggedIn: Boolean(userId),
  admin,
});

const Auth = ({ exact, loggedIn, path, component: Component }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) =>
      loggedIn ? <Redirect exact to="/" /> : <Component {...props} />
    }
  />
);

const Protected = ({ exact, loggedIn, path, component: Component }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) =>
      loggedIn ? <Component {...props} /> : <Redirect to="/signin" />
    }
  />
);

const Admin = ({ exact, loggedIn, admin, path, component: Component }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) =>
      loggedIn && admin ? (
        <Component {...props} />
      ) : loggedIn ? (
        <Redirect to="/" />
      ) : (
        <Redirect to="/signin" />
      )
    }
  />
);

export const AuthRoute = withRouter(connect(mapStateToProps)(Auth));
export const ProtectedRoute = withRouter(connect(mapStateToProps)(Protected));
export const AdminRoute = withRouter(connect(mapStateToProps)(Admin));
