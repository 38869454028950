import axios from "axios";
export const getTrucks = () => axios.get("/api/trucks");

export const getActiveTrucks = () => axios.get("/api/trucks?active=true");

export const registerTruck = (truck) => axios.post("/api/trucks", { ...truck });

export const updateTruck = (truck) =>
  axios.put(`/api/trucks/${truck._id}`, { ...truck });

export const getTruck = (id) => axios.get(`/api/trucks/${id}`);
