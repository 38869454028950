import * as apiUtil from "../util/session";
import { RECEIVE_CURRENT_USER, LOGOUT_CURRENT_USER } from "./types";
import { receiveErrors } from "./messages";
import { pendingResponse } from "./pending";

const receiveCurrentUser = (user) => ({
  type: RECEIVE_CURRENT_USER,
  user,
});

const logoutCurrentUser = () => ({
  type: LOGOUT_CURRENT_USER,
});

export const signin = (user) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.signin(user);
    dispatch(receiveCurrentUser(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};
export const register = (user) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.register(user);
    dispatch(receiveCurrentUser(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const logout = () => async (dispatch) => {
  try {
    await apiUtil.logout();
    return dispatch(logoutCurrentUser());
  } catch (err) {
    console.log(err);
    return dispatch(receiveErrors(err.response.data));
  }
};
