import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { signin } from "../../actions/session";

const mapStateToProps = ({ options: { inSpanish } }) => ({
  inSpanish,
});

const Signin = ({ signin, inSpanish }) => {
  const onSubmitSignIn = (e) => {
    e.preventDefault();
    const user = {
      [e.target[1].name]: e.target[1].value,
      [e.target[2].name]: e.target[2].value,
    };
    signin(user);
  };

  return (
    <div>
      <article className="br3 ba bw3 white b--white-20 mv4 w-100 w-50-m w-25-l mw6 shadow-3 center">
        <form className="pa4 white-80" onSubmit={onSubmitSignIn}>
          <div className="measure">
            <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
              <legend className="f1 fw6 ph0 mh0 subheader">
                {inSpanish ? "Iniciar Session" : "Sign In"}
              </legend>
              <p className="f3">
                Welcome, feel free to log in or register and test anything you'd
                like!
              </p>
              <div className="mt3">
                <label className="db fw6 lh-copy f6" htmlFor="username">
                  {inSpanish ? "Nombre de Usuario" : "Username"}
                </label>
                <input
                  className="pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                  type="text"
                  name="username"
                  defaultValue="test"
                  required
                />
              </div>
              <div className="mv3">
                <label className="db fw6 lh-copy f6" htmlFor="password">
                  {inSpanish ? "Contraseña" : "Password"}
                </label>
                <input
                  className="b pa2 input-reset ba black b--white bg-white-50 hover-bg-white w-100"
                  type="password"
                  name="password"
                  defaultValue="Access1234"
                  required
                />
              </div>
            </fieldset>
            <div className="">
              <input
                className="b ph3 pv2 input-reset ba white b--white bg-transparent grow pointer f5 dib"
                type="submit"
                value={inSpanish ? "Iniciar" : "Sign In"}
              />
            </div>
            <div className="lh-copy mt3 f6">
              {inSpanish ? "No tienes cuenta? " : "Don't have an account? "}
              <Link
                to="/register"
                className="f6 link dim white pointer underline"
              >
                {inSpanish ? "Registrate" : "Register"}
              </Link>
            </div>
          </div>
        </form>
      </article>
    </div>
  );
};

export default connect(mapStateToProps, { signin })(Signin);
