import * as apiUtil from "../util/trucks";
import { RECEIVE_TRUCKS } from "./types";
import { receiveErrors, receiveSuccess } from "./messages";
import { pendingResponse } from "./pending";

const receiveTrucks = (listTrucks) => ({
  type: RECEIVE_TRUCKS,
  listTrucks,
});

export const getTrucks = () => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.getTrucks();
    dispatch(receiveTrucks(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const getActiveTrucks = () => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.getActiveTrucks();
    dispatch(receiveTrucks(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const getTruck = (id) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response = await apiUtil.getTruck(id);
    dispatch(receiveTrucks(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};

export const uploadTruck = (truck = null) => async (dispatch) => {
  try {
    dispatch(pendingResponse(true));
    const response =
      truck._id === ""
        ? await apiUtil.registerTruck(truck)
        : await apiUtil.updateTruck(truck);
    dispatch(receiveSuccess(response.data));
    return dispatch(pendingResponse(false));
  } catch (err) {
    console.log(err);
    dispatch(receiveErrors(err.response.data));
    return dispatch(pendingResponse(false));
  }
};
