import React, { Component } from "react";
import { connect } from "react-redux";
import WalkaroundForm from "../../Components/WalkaroundForm/WalkaroundForm";
import {
  getInspection,
  getInspections,
  displayInspection,
} from "../../actions/inspections";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import "./FullWalkaround.css";

const mapStateToProps = ({
  inspections: { inspections, query },
  options: { inSpanish },
}) => {
  return {
    inspections,
    inSpanish,
    query,
  };
};

class FullWalkaround extends Component {
  render() {
    const {
      inSpanish,
      inspections,
      displayInspection,
      getInspections,
      query,
    } = this.props;
    return (
      <div className="fullScreen">
        <div className="flex">
          <div className="left" style={{ float: "left" }}>
            <button
              className="b br3 ma2 pr3 pv1 ba white hover-light-red b--white bg-black-40 grow pointer f5 center"
              onClick={() => {
                displayInspection("", false, "");
                getInspections(query);
              }}
            >
              <ArrowLeftIcon />
              {inSpanish ? "Regresar" : "Back"}
            </button>
          </div>
          <h1 className="center di white">
            {inspections[0].truckID.truckNumber}
          </h1>
        </div>
        <div className="flex center" style={{ height: "100%" }}>
          <WalkaroundForm
            inspection={inspections[0].walkaround1}
            inSpanish={inSpanish}
            walkaround="walkaround1"
          />

          <WalkaroundForm
            inspection={inspections[0].walkaround2}
            inSpanish={inSpanish}
            walkaround="walkaround2"
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  getInspection,
  displayInspection,
  getInspections,
})(FullWalkaround);
